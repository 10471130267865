import { ColumnDef } from "@tanstack/react-table"
import React, { useRef, useState } from "react"
import ReactTable from "../../../components/ReactTable"
import { Behandling } from "../../../hooks/behandlinger/types"
import useBehandlinger from "../../../hooks/behandlinger/useBehandlinger"
import { formatDate } from "../../../utils/formatDate"
import BehandlingListDetailPanel from "./BehandlingListDetailPanel"

import SakItem from "../../sak/SakList/SakItem"
import { Box, Button, Chip, Typography } from "@mui/material"
import DisplayPosInMap from "../../map/DisplayPosInMap"
import useArealplan from "../../../hooks/arealplaner/useArealplan"
import useCan from "../../../utils/auth/useCan"
import { useBehandlingActions } from "./useBehandlingActions"
import { BehandlingTableRowMenuButton } from "./BehandlingTableRowMenuButton"
import AddIcon from "@mui/icons-material/Add"
import { ActionButtonProps } from "../../../components/ActionButtons/ActionButton"
import ActionButtons from "../../../components/ActionButtons"
import { useDispatch, useSelector } from "react-redux"
import { getExpandBehandlingId } from "../../../store"
import { setExpandBehandlingId } from "../../../store/session/actions"
import { showChipWithHiddenMsg, sortBehandlingList } from "./helpers"

export const BehandlingTable = () => {
  const behandlinger = useBehandlinger()
  const can = useCan()
  const { data: plan } = useArealplan()
  const selectedBehandling = useRef<Behandling>({} as Behandling)
  const [displayPosFor, setDisplayPosFor] = useState<Behandling | null>()
  const actions = useBehandlingActions()
  const expandId = useSelector(getExpandBehandlingId)
  const dispatch = useDispatch()

  const onExpandChange = (id: string) => {
    dispatch(setExpandBehandlingId(id == expandId ? undefined : id))
  }

  const columns = React.useMemo<ColumnDef<Behandling>[]>(
    () => [
      {
        id: "dato",
        header: "Dato",
        size: 120,
        accessorFn: row => row.fraDato,
        sortingFn: "datetime",
        cell: ({ row }) =>
          formatDate(row.original.fraDato) +
          (row.original.tilDato ? " - " + formatDate(row.original.tilDato) : "")
      },
      {
        id: "behandlingstype",
        header: "Behandlingstype",
        accessorFn: row => row.behandlingstype,
        sortingFn: "text",
        cell: info => (
          <>
            <Typography>{info.row.original.behandlingstype}</Typography>
            {showChipWithHiddenMsg(info.row.original) && (
              <Chip label={"Skjult"} />
            )}
          </>
        )
      },
      {
        id: "beskrivelse",
        header: "Beskrivelse",
        accessorFn: row => row.beskrivelse,
        sortingFn: "text",
        // eslint-disable-next-line react/prop-types
        cell: ({ getValue }) => (
          <Box
            sx={{
              wordBreak: "keep-all",
              overflowWrap: "break-word"
            }}>
            {getValue<string>()}
          </Box>
        )
      },
      {
        id: "dokumenter",
        header: "Dokumenter",
        size: 151,
        accessorFn: row => row.dokumenter,
        cell: info => (
          <span
            style={{
              whiteSpace: "nowrap"
            }}>
            {info.row.original.dokumenter.length > 0
              ? `${info.row.original.dokumenter.length} dokumenter`
              : ""}
          </span>
        )
      },
      {
        id: "sak",
        header: "Saksnummer",
        size: 151,
        accessorFn: row => row.sak,
        cell: info => (
          <span
            style={{
              whiteSpace: "nowrap"
            }}>
            {info.row.original.sak && <SakItem sak={info.row.original.sak} />}
          </span>
        )
      },
      {
        id: "posisjon",
        header: "Posisjon",
        size: 100,
        accessorFn: row => row.posisjon,
        enableSorting: false,
        cell: info => (
          <span
            style={{
              whiteSpace: "nowrap"
            }}>
            {info.row.original.posisjon?.x && info.row.original.posisjon?.y ? (
              <Button
                onClick={e => {
                  e.stopPropagation()
                  setDisplayPosFor(info.row.original)
                }}>
                Vis i kart
              </Button>
            ) : (
              ""
            )}
          </span>
        )
      },
      ...(can.edit
        ? [
            {
              id: "rowMenu",
              enableSorting: false,
              size: 52,
              cell: info => (
                <BehandlingTableRowMenuButton
                  behandling={info.row.original}
                  onOpen={behandling =>
                    (selectedBehandling.current = behandling)
                  }
                />
              )
            }
          ]
        : [])
    ],

    [can.edit]
  )
  const actionButtons = [
    {
      title: (
        <>
          <AddIcon /> Ny behandling
        </>
      ),
      label: "Ny behandling",
      onClick: () => {
        actions.create()
      }
    }
  ] as ActionButtonProps[]

  return (
    <div>
      {can.edit && <ActionButtons actionButtons={actionButtons} />}

      {displayPosFor?.posisjon && (
        <DisplayPosInMap
          plan={plan}
          title="Posisjonen til behandlingen"
          onCloseMap={() => setDisplayPosFor(null)}
          position={displayPosFor.posisjon}
        />
      )}
      <ReactTable
        columns={columns}
        data={sortBehandlingList(behandlinger.data)}
        toolbar={{ enableFilter: true }}
        renderSubComponent={({ row }) => (
          <BehandlingListDetailPanel behandling={row.original} />
        )}
        expandId={expandId}
        onExpandChanged={onExpandChange}
        noDataText="Ingen behandlinger funnet"
      />
      <input
        id="file-upload"
        type="file"
        multiple={true}
        style={{ display: "none" }}
        onChange={e =>
          actions.importFilesFromDisk(e, selectedBehandling.current)
        }
        onClick={e => {
          e.currentTarget.value = ""
        }}
      />
    </div>
  )
}
