import * as React from "react"

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  RadioGroup,
  Typography
} from "@mui/material"
import {
  Dispensasjon,
  DispensasjonFormData
} from "../../../hooks/dispensasjoner/types"
import { Field, Form, FormSpy } from "react-final-form"

import { Arealplan } from "../../../hooks/arealplaner/types"
import { AxiosError } from "axios"
import DatePicker from "../../../components/DatePicker"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import Error from "../../../components/FormError/Error"
import FormError from "../../../components/FormError"
import FormInputField from "../../../components/FormInputField"
import FormSelectInput from "../../../components/FormSelectInput"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { MapboxLayer } from "@norkart/nkm-mapbox-map"
import PositionOptions from "../../../components/PositionOptions"
import Radiobutton from "../../../components/Radiobutton/Radiobutton"
import SpinnerButton from "../../../components/SpinnerButton"
import { parseDate } from "../../../utils/parseDate"
import useMeta from "../../../hooks/meta/useMeta"
import { useState } from "react"
import "./dispform.css"
import config from "../../../config"

export interface DispFormProps {
  disp: Dispensasjon
  plan: Arealplan
  dispIsSubmitting: boolean
  onSubmit: (data: DispensasjonFormData) => Promise<Dispensasjon>
  onCancel: () => void
  onDelete: () => void
  onVertikalNivaaChange: (data: number) => void
  focusRow: (id: string) => void
  setDisplaySelectMapPosition: (value: boolean) => void
  coords: { x: number; y: number } | undefined
  onPositionSelected: (coords: { x: number; y: number } | undefined) => void
  setCoords: React.Dispatch<
    React.SetStateAction<
      | {
          x: number
          y: number
        }
      | undefined
    >
  >
  onDeleteCoords: () => void
  coordsHasChanged: boolean
  dispFraTyper: { Key: string; Value: string }[]
  planAreaLayer: MapboxLayer | undefined
  getDefaultVertikalniva: (
    disp: Dispensasjon,
    plan: Arealplan
  ) => number | undefined
}

type error = object

export interface DispFormState {
  errors?: AxiosError
  editSakVerdiId: boolean
  values: DispensasjonFormData
}

function DispForm({ plan, disp, ...props }: DispFormProps) {
  const meta = useMeta()
  const valuesRef = React.useRef<{ values: string } | any>()
  const [editSakVerdiId, setEditSakVerdiId] = useState(false)
  const [formValues, setFormValues] = useState({})

  const validationSchema = (values): error => {
    const errors = {} as any
    if (!values.dispensasjonTypeId)
      errors.dispensasjonTypeId = "Dispensasjonstype er påkrevd"
    if (!values.sak)
      errors.sak = {
        sakAar: "Saksår er påkrevd",
        sakSeknr: "Sekvensnummer er påkrevd"
      }
    if (!values.sak && values.journalpostnummer)
      errors.journalpostnummer = "Journalpostnummer krever saksår"
    if (values.sak && !values.sak.sakAar)
      errors.sak = { ...errors.sak, sakAar: "Saksår er påkrevd" }
    if (values.sak && !values.sak.sakSeknr)
      errors.sak = { ...errors.sak, sakSeknr: "Sekvensnummer er påkrevd" }
    if (!values.vedtaksdato) errors.vedtaksdato = "Vedtaksdato er påkrevd"
    if (values.beskrivelse && values.beskrivelse.length > 1000)
      errors.beskrivelse = "Beskrivelsen overskrider 1000 tegn."
    return errors
  }

  React.useEffect(() => {
    if (props.coords) {
      if (valuesRef.current) {
        valuesRef.current.xkoord = props.coords.x
        valuesRef.current.ykoord = props.coords.y
      }
    } else {
      if (valuesRef.current) {
        valuesRef.current.xkoord = ""
        valuesRef.current.ykoord = ""
      }
    }
  }, [props.coords, formValues])

  React.useEffect(() => {
    const initialArkivId = meta.aktivtArkiv

    const initialValues: Partial<DispensasjonFormData> = disp.id
      ? disp
      : {
          sak: {
            arkivId: initialArkivId
          },
          vertikalnivaId: props.getDefaultVertikalniva(disp, plan)
        }

    if (Object.keys(formValues).length == 0) {
      setFormValues(initialValues)
    }
  }, [])

  if (meta.isLoading) return <LoadingSpinner />
  return (
    <>
      <Form
        onSubmit={props.onSubmit}
        initialValues={formValues}
        validate={validationSchema}
        render={({
          handleSubmit,
          pristine,
          submitting,
          errors,
          submitErrors,
          invalid,
          values,
          form
        }) => (
          <Box
            sx={theme => ({
              form: {
                "& .importantFormLabel": { fontWeight: 600 },
                "& .formInput": {
                  width: "100%",
                  padding: "5px",
                  fontFamily: theme.typography.fontFamily
                },
                "& .formInput:disabled": { backgroundColor: "#f0f0f0" },
                "& .formInputError": { border: "1px solid red" },
                "& .textarea": { fontSize: "inherit" }
              }
            })}>
            <form onSubmit={handleSubmit} autoComplete="off">
              <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) => {
                  valuesRef.current = values
                }}
              />
              <Grid container={true} spacing={6}>
                <Grid item={true} xs={6}>
                  <Typography
                    className="importantFormLabel"
                    style={{ whiteSpace: "nowrap" }}>
                    Saksår
                  </Typography>
                  <FormInputField
                    name="sak.sakAar"
                    maxLength={4}
                    placeholder="saksår"
                    optional={false}
                    fieldProps={{
                      parse: value => {
                        if (value == "") return value
                        return Number(value.replace(/[^\d]/g, ""))
                      }
                    }}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography
                    className="importantFormLabel"
                    sx={{ whiteSpace: "nowrap" }}>
                    Sakssekvensnummer
                  </Typography>
                  <FormInputField
                    name="sak.sakSeknr"
                    placeholder="sekvensnr"
                    optional={false}
                    fieldProps={{
                      parse: value => {
                        if (value == "") return value
                        return Number(value.replace(/[^\d]/g, ""))
                      }
                    }}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <FormInputField
                    name="journalpostnummer"
                    label="Journalpostnummer"
                    disabled={values.sak?.sakAar ? false : true}
                    optional={true}
                    fieldProps={{
                      parse: value => {
                        if (value == "") return undefined
                        return Number(value.replace(/[^\d]/g, ""))
                      }
                    }}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography
                    className="importantFormLabel"
                    sx={{ whiteSpace: "nowrap" }}>
                    <span
                      style={{
                        height: 24,
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row"
                      }}>
                      Arkiv
                      <IconButton
                        onClick={() => setEditSakVerdiId(!editSakVerdiId)}
                        size="small">
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Typography>
                  <Grid
                    container={true}
                    spacing={8}
                    justifyContent="space-between">
                    <Grid item={true} xs={12}>
                      <FormSelectInput
                        width="100%"
                        hideMinWidth={true}
                        name="sak.arkivId"
                        options={meta.data.arkiver}
                        keyCol="id"
                        valueCol="beskrivelse"
                        displayId={false}
                        optional={false}
                        disabled={!editSakVerdiId}
                        onBlur={() => setEditSakVerdiId(false)}
                        nullOption={false}
                        parse={v => Number(v)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} item={true}>
                  <Typography className="importantFormLabel">
                    Vedtaksdato
                  </Typography>
                  <div style={{ height: "42px" }}>
                    <Field
                      name="vedtaksdato"
                      component={DatePicker}
                      parse={parseDate}
                    />
                    <Field
                      name="vedtaksdato"
                      subscription={{
                        error: true,
                        submitError: true,
                        touched: true,
                        dirtySinceLastSubmit: true
                      }}
                      render={({ meta }) =>
                        meta.touched &&
                        meta.error &&
                        !meta.dirtySinceLastSubmit ? (
                          <Error error={meta.error} />
                        ) : null
                      }
                    />
                  </div>
                </Grid>
                <Grid xs={12} item={true}>
                  <FormSelectInput
                    name="dispensasjonTypeId"
                    title="Dispensasjonstype"
                    options={meta.data.dispensasjonstyper}
                    keyCol="id"
                    valueCol="beskrivelse"
                    displayId={true}
                    optional={false}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Typography>Beskrivelse av dispensasjon</Typography>
                  <Field
                    name="beskrivelse"
                    component="textarea"
                    className="formInput textarea"
                    rows={3}
                  />
                  <FormError name={"beskrivelse"} />
                </Grid>
                <Grid item={true} xs={12}>
                  {props.coords &&
                  props.coords.x &&
                  props.dispFraTyper.length ? (
                    <FormSelectInput
                      name="dispFra"
                      title="Dispensasjon fra"
                      options={props.dispFraTyper}
                      keyCol="Key"
                      valueCol="Value"
                      displayId={false}
                      optional={false}
                    />
                  ) : null}
                </Grid>
                <Grid item={true} xs={12}>
                  <FormSelectInput
                    name="vedtakId"
                    title="Vedtakstyper"
                    options={meta.data.vedtakstyper}
                    keyCol="id"
                    valueCol="beskrivelse"
                    displayId={false}
                    optional={true}
                    parse={v => Number(v)}
                  />
                </Grid>
                {plan.vertikalniva && plan.vertikalniva.length ? (
                  <Grid item={true} xs={12}>
                    <Typography>Vertikalnivå</Typography>
                    <FormControl component="fieldset">
                      <RadioGroup>
                        {plan.vertikalniva.map(v => (
                          <FormControlLabel
                            key={v.id}
                            control={
                              <Field
                                name="vertikalnivaId"
                                type="radio"
                                component={Radiobutton}
                                parse={v => {
                                  props.onVertikalNivaaChange(Number(v))
                                  return Number(v)
                                }}
                                value={v.id}
                              />
                            }
                            label={v.beskrivelse}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                ) : null}
                <Grid item={true} xs={12}>
                  <Typography style={{ fontWeight: 500 }}>
                    Valgt posisjon
                  </Typography>
                  <PositionOptions
                    planAreaLayer={props.planAreaLayer}
                    coords={props.coords}
                    setCoords={props.setCoords}
                    setDisplaySelectMapPosition={
                      props.setDisplaySelectMapPosition
                    }
                    allFormData={valuesRef}
                  />
                </Grid>
                <Grid item={true} xs={12} style={{ marginTop: "20px" }}>
                  <Grid
                    container={true}
                    justifyContent="space-between"
                    alignItems="baseline">
                    <Grid item={true}>
                      {disp &&
                        disp.id &&
                        ((
                          <IconButton onClick={props.onDelete}>
                            <DeleteIcon />
                          </IconButton>
                        ) ||
                          null)}
                    </Grid>
                    <Grid item={true}>
                      <Button
                        variant="contained"
                        color="grey"
                        onClick={props.onCancel}
                        disabled={submitting || props.dispIsSubmitting}>
                        Avbryt
                      </Button>
                      <SpinnerButton
                        type="submit"
                        label="Lagre"
                        style={{ marginLeft: "20px" }}
                        disabled={
                          submitting ||
                          invalid ||
                          (pristine && !props.coordsHasChanged) ||
                          props.dispIsSubmitting
                        }
                        loading={submitting || props.dispIsSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
            <pre>subErrors: {JSON.stringify(submitErrors, null, 2)}</pre>
            <pre>Values: {JSON.stringify(values, null, 2)}</pre> */}
            </form>
          </Box>
        )}
      />
    </>
  )
}

export default DispForm
