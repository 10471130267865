import { Grid } from "@mui/material"
import React, { useState } from "react"
import DesktopContainer from "../../../containers/DesktopContainer"
import { Dispensasjon } from "../../../hooks/dispensasjoner/types"
import DispensasjonListItem from "./DispensasjonListItem"
import DisplayPosInMap from "../../map/DisplayPosInMap"
import ExpansionPanel from "../../../components/ExpansionPanel"
import HelpButton from "../../../components/HelpButton/HelpButton"
import HelpText from "../../../components/HelpText/HelpText"
import {MapRoutePins1Icon} from '@norkart/toi-icons';
import MobileContainer from "../../../containers/MobileContainer"
import { helpObject } from "../../../help/helpObject"
import useArealplan from "../../../hooks/arealplaner/useArealplan"
import useDispensasjoner from "../../../hooks/dispensasjoner/useDispensasjoner"
import { useDispatch } from "react-redux"
import { setMapMode } from "../../../store/map/actions"
import { DispensasjonTable } from "./DispensasjonTable"
import ActionButtons from "../../../components/ActionButtons"
import { ActionButtonProps } from "../../../components/ActionButtons/ActionButton"
import { orderBy } from "lodash-es"

const DispensasjonList = () => {
  const [expandedId, setExpandedId] = React.useState<number | undefined>()
  const [displayPosFor, setDisplayPosFor] = useState<Dispensasjon | null>()

  const { data: plan } = useArealplan()
  const { data: dispensasjoner } = useDispensasjoner()

  const dispatch = useDispatch()

  const handleSetMapMode = (mode: boolean) => {
    dispatch(setMapMode(mode, true))
  }

  const onChange = (isExpanded: boolean, rowIndex: number) => {
    if (isExpanded) {
      setExpandedId(rowIndex)
    } else if (expandedId && !isExpanded) {
      setExpandedId(undefined)
    }
  }

  const mobileActionButtons = [
    {
      title: (
        <>
          <MapRoutePins1Icon /> Vis i kart
        </>
      ),
      label: "Vis i kart",
      onClick: () => {
        handleSetMapMode(true)
      }
    }
  ] as ActionButtonProps[]

  return (
    <>
      {displayPosFor?.posisjon && (
        <DisplayPosInMap
          title="Posisjonen til dispensasjonen"
          plan={plan}
          onCloseMap={() => setDisplayPosFor(null)}
          position={displayPosFor.posisjon}
          vertikalnivaId={displayPosFor.vertikalnivaId}
        />
      )}
      <MobileContainer>
        <ExpansionPanel
          helpButtonText={helpObject.Faner.Dispensasjoner.MeromDispensasjoner}
          title={`Dispensasjoner (${dispensasjoner && dispensasjoner.length})`}
          padding="0"
          margin="10px">
          <ActionButtons actionButtons={mobileActionButtons} />
          {dispensasjoner &&
            orderBy(dispensasjoner, "vedtaksdato", ["desc"]).map(
              dispensasjon => {
                return (
                  <DispensasjonListItem
                    dispensasjon={dispensasjon}
                    key={dispensasjon.id}
                    expandedOptions={{
                      expandedId: expandedId,
                      onChange: isExpanded =>
                        onChange(isExpanded, dispensasjon.id),
                      allowMultipleExpanded: false,
                      rowId: dispensasjon.id
                    }}
                  />
                )
              }
            )}
        </ExpansionPanel>
      </MobileContainer>
      <DesktopContainer>
        <Grid
          container={true}
          direction={"column"}
          style={{ display: "flex", marginBottom: 40 }}>
          <HelpText
            style={{ marginRight: 50 }}
            helpText={helpObject.Faner.Dispensasjoner.hovedtekst}
          />
          <HelpButton
            helpText={helpObject.Faner.Dispensasjoner.MeromDispensasjoner}
            type="MeromDispensasjoner"
            buttonVariant="tekst"
          />
        </Grid>
        <DispensasjonTable />
      </DesktopContainer>
    </>
  )
}

export { DispensasjonList }
