import { FitBounds } from "@norkart/nkm-mapbox-map"
import {
  GFIAttrState,
  MapActionTypes,
  PlanAreaLayers,
  WmsLayersRequestState
} from "./types"

import { AvailableWmsLayer } from "../../features/map/types"
import { action } from "typesafe-actions"
import { gfiAttId } from "../../services/apiTjenestekatalogen"

export const refreshWmsLayers = (displaySurroundingLayers: boolean) =>
  action(MapActionTypes.REFRESH_WMS_LAYERS, displaySurroundingLayers)
export const addWmsLayer = (layer: AvailableWmsLayer) =>
  action(MapActionTypes.ADD_WMS_LAYER, layer)
export const removeWmsLayer = (layer: AvailableWmsLayer) =>
  action(MapActionTypes.REMOVE_WMS_LAYER, layer)
export const fetchWms = () => action(MapActionTypes.FETCH_WMS_REQUEST)
export const fetchWmsSuccess = (data: WmsLayersRequestState) =>
  action(MapActionTypes.FETCH_WMS_SUCCESS, data)
export const fetchWmsError = () => action(MapActionTypes.FETCH_WMS_ERROR)
export const fetchGfi = () => action(MapActionTypes.FETCH_gfi_REQUEST)
export const fetchGfiSuccess = (data: GFIAttrState) =>
  action(MapActionTypes.FETCH_gfi_SUCCESS, data)
export const fetchGfiError = () => action(MapActionTypes.FETCH_gfi_ERROR)
export const setMapMode = (fullscreen: boolean, showDispInMap: boolean) =>
  action(MapActionTypes.SET_MAP_MODE, { fullscreen, showDispInMap })
export const setDifferenceLayer = (layer: PlanAreaLayers) =>
  action(MapActionTypes.SET_DIFFERENCE_LAYER, layer)
export const setBorderLayers = (layers: PlanAreaLayers) =>
  action(MapActionTypes.SET_BORDER_LAYERS, layers)
export const addFitToBounds = (fitbounds?: FitBounds) =>
  action(MapActionTypes.ADD_FITTOBOUNDS, fitbounds)
export const setVisibleInMap = (value: boolean, id: gfiAttId) =>
  action(MapActionTypes.SET_VISIBLE_IN_MAP, { value, id })
export const resetMapState = () => action(MapActionTypes.RESET_STATE)
export const resetGFIState = () => action(MapActionTypes.RESET_GFI)
