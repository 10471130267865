import { Typography } from "@mui/material"
import React from "react"
import { Arealplan } from "../../../../hooks/arealplaner/types"
import PlanStatusChip from "../../PlanStatusChip"

type Props = { plan: Arealplan }
const PlanInfoHeader = ({ plan }: Props) => {
  return (
    <div className="PlanInfoHeader">
      <div style={{ display: "flex", alignItems: "center", marginTop: 25 }}>
        <Typography
          variant="h2"
          sx={theme => ({
            wordBreak: "break-word",
            [theme.breakpoints.down("sm")]: {
              fontSize: "1.2rem",
              fontWeight: 500
            }
          })}>
          {plan.planNavn}
        </Typography>
      </div>
      <div style={{ fontStyle: "italic" }}>
        <PlanStatusChip
          planStatusId={plan.planStatusId}
          planStatus={plan.planStatus}
        />
      </div>
    </div>
  )
}
export default PlanInfoHeader
