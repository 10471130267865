import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material"
import * as React from "react"
import useDeleteDispensasjon from "../../../hooks/dispensasjoner/useDeleteDispensasjon"
import { ConnectedReduxProps } from "../../../store"
import { Arealplan } from "../../../hooks/arealplaner/types"
import { hideDialog, showDialog } from "../../../store/dialog/actions"
import { Dispensasjon } from "../../../hooks/dispensasjoner/types"

export interface DeleteDispDialogProps extends ConnectedReduxProps {
  disp: Dispensasjon
  plan: Arealplan
  open: boolean
  focusRow: (id: string) => void
}

const DeleteDispDialog = ({
  disp,
  plan,
  focusRow,
  open,
  dispatch
}: DeleteDispDialogProps) => {
  const { mutate: deleteDisp } = useDeleteDispensasjon()

  const handleClose = () => {
    dispatch(
      showDialog({
        dialogType: "EDIT_DISP",
        dialogProps: { plan, disp, focusRow: focusRow }
      })
    )
  }

  const handleOk = () => {
    deleteDisp(disp.id)
    hideDialog(dispatch)
  }
  return (
    <Dialog open={open} aria-labelledby="confirm-dialog-title">
      <DialogTitle id="confirm-dialog-title">
        Vil du virkelig slette dispensasjonen?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Nei</Button>
        <Button onClick={handleOk}>Ja</Button>
      </DialogActions>
    </Dialog>
  )
}
export default DeleteDispDialog
