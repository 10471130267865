import React from "react"
import { Button, styled } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import MapIcon from "@mui/icons-material/Map"
import EditIcon from "@mui/icons-material/Edit"
import useMeta from "../../../../hooks/meta/useMeta"

const ActionButtonGroupWrapper = styled("div")(({ theme }) => ({
  marginTop: "5px",
  marginBottom: "5px",
  marginLeft: "-11px",
  display: "flex",
  flexWrap: "wrap",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    marginBottom: "10px"
  }
}))

const ButtonRow = styled("div")({ display: "flex", justifyContent: "center" })

const ActionButton = styled(Button)(({ theme }) => ({
  marginRight: "4px",
  [theme.breakpoints.down("sm")]: { width: "80%", backgroundColor: "red" },
  "& p": { fontSize: "1rem", margin: "5px", fontWeight: 400 },
  "& svg": { marginRight: "4px" }
}))

type ParentProps = {
  handleEditClick: () => void
  handleMapClick: (() => void) | undefined
  handleSearch: () => void
  handleMapClickVisGI: (() => void) | undefined
  userCanEdit: boolean
  userCanView: boolean
}
type Props = ParentProps

const TopButtonMenu = ({
  handleMapClick,
  handleSearch,
  handleMapClickVisGI,
  userCanEdit,
  userCanView,
  handleEditClick
}: Props) => {
  const meta = useMeta()
  return (
    <ActionButtonGroupWrapper>
      <ButtonRow>
        <ActionButton variant="text" size="small" onClick={handleSearch}>
          <SearchIcon fontSize="small" />
          <p>Til plansøk</p>
        </ActionButton>
        {handleMapClick && meta.visplanUrl && (
          <ActionButton variant="text" size="small" onClick={handleMapClick}>
            <MapIcon fontSize="small" />
            <p>{meta.visplanTekst || "Flere kartfunksjoner"}</p>
          </ActionButton>
        )}
      </ButtonRow>
      <ButtonRow>
        {handleMapClickVisGI && meta.visplanInternUrl && userCanView && (
          <ActionButton
            variant="text"
            size="small"
            onClick={handleMapClickVisGI}>
            <MapIcon fontSize="small" style={{ color: "green" }} />
            <p>{meta.visplanInternTekst || "VisGI"}</p>
          </ActionButton>
        )}
        {userCanEdit && (
          <ActionButton variant="text" size="small" onClick={handleEditClick}>
            <EditIcon fontSize="small" />
            <p>Endre plan</p>
          </ActionButton>
        )}
      </ButtonRow>
    </ActionButtonGroupWrapper>
  )
}
export default TopButtonMenu
