import * as React from "react"
import { Tabs, Tab, Badge, styled } from "@mui/material"
import DesktopContainer from "../../../../../containers/DesktopContainer"
import { Arealplan } from "../../../../../hooks/arealplaner/types"
import { capitalize } from "../../../../../utils/capitalize"
import { setTitle } from "../../../../../utils/setTitle"
import { hoverOverWhiteColor } from "../../../../../theme"
import { ApplicationState, getActivePlanTab } from "../../../../../store"
import { connect } from "react-redux"
import { setActivePlanTab } from "../../../../../store/session/actions"
import useDispensasjoner from "../../../../../hooks/dispensasjoner/useDispensasjoner"
import useBehandlinger from "../../../../../hooks/behandlinger/useBehandlinger"
import useCan from "../../../../../utils/auth/useCan"
import usePlanViewHelpers from "../../usePlanViewHelpers"
import useArealplanDokumenter from "../../../../../hooks/dokumenter/useArealplanDokumenter"

export interface PlanTabsProps {
  plan: Arealplan
  activePage: string
  onChange: (string) => void
  activeTab
  setActiveTab
}

type PlanPage =
  | "plandokumenter"
  | "planbehandlinger"
  | "dispensasjoner"
  | "planforhold"
  | "saksdokumenter"
  | "tidsakse"
  | "gi tilbakemelding"
  | "beskrivelse"

const StyledTab = styled(Tab)(({ theme }) => ({
  padding: "10px 22px",
  fontSize: "1.05rem",
  "&:hover": {
    backgroundColor: hoverOverWhiteColor
  },
  [theme.breakpoints.down("md")]: { fontSize: "0.8rem" }
}))

const PlanTabsRender = ({
  onChange,
  setActiveTab,
  activeTab = 0,
  plan
}: PlanTabsProps) => {
  const can = useCan()
  const dokumenter = useArealplanDokumenter()
  const behandlinger = useBehandlinger()
  const dispensasjoner = useDispensasjoner()
  const helpers = usePlanViewHelpers()

  const allPages: { id: PlanPage; visible: boolean; count?: number }[] = [
    {
      id: "beskrivelse",
      visible:
        can.edit || (!!plan.planBeskrivelse && plan.planBeskrivelse.length > 0)
    },
    {
      id: "plandokumenter",
      count: dokumenter.count,
      visible: can.edit
        ? true
        : dokumenter.count > 0 && dokumenter.andreDokumenter.length > 0
    },
    {
      id: "planbehandlinger",
      count: behandlinger.data.length,
      visible: can.edit ? true : behandlinger.data.length > 0
    },
    {
      id: "dispensasjoner",
      count: dispensasjoner.data.length,
      visible: can.editDisp ? true : dispensasjoner.data.length > 0
    },
    {
      id: "planforhold",
      count:
        plan.planforholdEtterkommere.length + plan.planforholdForlopere.length,
      visible: can.edit
        ? true
        : plan.planforholdEtterkommere.length +
            plan.planforholdForlopere.length >
          0
    },
    {
      id: "saksdokumenter",
      visible: helpers.shouldShowSaksdokumenter(
        plan.planStatusId,
        plan.saker[0]
      )
    },
    {
      id: "tidsakse",
      visible: helpers.shouldShowTidsakse(plan.planStatusId, plan.saker[0])
    },
    {
      id: "gi tilbakemelding",
      visible: helpers.shouldShowTilbakemelding(plan.planStatusId)
    }
  ]

  React.useEffect(() => {
    const visiblePages = getVisiblePages()
    const activeTabId = visiblePages[activeTab]
      ? visiblePages[activeTab].id
      : null
    let activePage: PlanPage = activeTabId || getInitPlanTab()

    let activeId: number = visiblePages.indexOf(
      visiblePages.filter(page => page.id === activePage)[0]
    )
    if (activeId < 0) {
      //active page not visible for user, setting first in list active instead
      activeId = 0
    }
    handleChange(null, activeId)
  }, [plan.id])

  React.useEffect(() => {
    return () => {
      setActivePlanTab(undefined)
    }
  }, [])

  const getInitPlanTab = () => {
    return plan.planStatusId == 3 ? "plandokumenter" : "planbehandlinger"
  }

  const visiblePages = allPages.filter(page => page.visible)

  const getVisiblePages = (): {
    id: PlanPage
    visible: boolean
    count?: number
  }[] => {
    return allPages.filter(page => {
      return page.visible
    })
  }

  const handleChange = (event, pageId: number) => {
    setActiveTab(pageId)
    if (visiblePages[pageId]) {
      setTitle(`${plan.planId} > ${capitalize(visiblePages[pageId].id)}`)
      onChange(visiblePages[pageId].id)
    } else {
      onChange(-1) //Do not show anything
    }
  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    padding: theme.spacing(1, 8),
    ".MuiBadge-badge": {
      color: `${theme.palette.primary.main}`,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: "white"
    }
  }))

  return (
    <DesktopContainer>
      {visiblePages.length === 0 ? (
        <div style={{ marginLeft: 35, marginTop: 30 }}>
          Det finnes ingen dokumenter på denne planen
        </div>
      ) : (
        <div
          style={{
            marginTop: 30,
            marginLeft: 30,
            width: "100vw",
            display: "flex",
            justifyContent: "flex-start"
          }}>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            indicatorColor={"secondary"}>
            {visiblePages.map(page => (
              <StyledTab
                key={page.id}
                label={
                  <StyledBadge
                    max={9999}
                    badgeContent={page.count}
                    overlap={"rectangular"}>
                    {page.id}
                  </StyledBadge>
                }
              />
            ))}
          </Tabs>
        </div>
      )}
    </DesktopContainer>
  )
}
const PlanTabs = connect(
  (state: ApplicationState) => ({
    activeTab: getActivePlanTab(state)
  }),
  dispatch => ({
    setActiveTab: (activeTab: string) => dispatch(setActivePlanTab(activeTab))
  })
)(PlanTabsRender)

export default PlanTabs
