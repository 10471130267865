
import React, { RefObject, useCallback } from "react"
import { useDispatch } from "react-redux"
import { Sak } from "../../../hooks/arealplaner/types"
import useArealplan from "../../../hooks/arealplaner/useArealplan"
import { Behandling } from "../../../hooks/behandlinger/types"
import { showDialog } from "../../../store/dialog/actions"
import { setExpandBehandlingId } from "../../../store/session/actions"
import { ImportDocumentDialogProps } from "../../dokumenter/ImportDocumentDialog/ImportDocumentDialog"
import { orderBy } from "lodash-es"

export const useBehandlingActions = () => {
  const dispatch = useDispatch()
  const { data: plan } = useArealplan()

  const focusRow = useCallback((id: string) => {
    dispatch(setExpandBehandlingId(id))
  }, [])

  const create = () => {
    let behandling = {} as Behandling
    if (plan.saker.length > 0) {
      let highestSaksYear: number = 0
      plan.saker.forEach(sak => {
        highestSaksYear =
          sak.sakAar > highestSaksYear ? sak.sakAar : highestSaksYear
      })
      const sakerWithHighestYear: Sak[] = plan.saker.filter(
        sak => sak.sakAar === highestSaksYear
      )
      let highestSaksnummer: number = 0
      sakerWithHighestYear.filter(
        sak =>
          (highestSaksnummer =
            sak.sakSeknr > highestSaksnummer ? sak.sakSeknr : highestSaksnummer)
      )
      const sakToDisplay = sakerWithHighestYear.find(sak => sak.sakSeknr)
      if (sakToDisplay) behandling.sak = sakToDisplay
    }
    dispatch(
      showDialog({
        dialogType: "EDIT_BEHANDLING",
        dialogProps: { behandling, plan, focusRow }
      })
    )
  }

  const edit = (behandling: Behandling) => {
    dispatch(
      showDialog({
        dialogType: "EDIT_BEHANDLING",
        dialogProps: { behandling, plan, focusRow }
      })
    )
  }

  const importFilesFromDisk = (event, behandling: Behandling) => {
    const files = Array.from(event.target.files) as File[]
    dispatch(
      showDialog({
        dialogType: "IMPORT_DOCUMENTS",
        dialogProps: {
          files,
          behandling: behandling,
          plan,
          showRowDetails: focusRow
        } as ImportDocumentDialogProps
      })
    )

    // Trick input to fire onChange every time. Does not work on IE.
    // @ts-ignore
    const isIE = /*@cc_on!@*/ false || !!document.documentMode
    if (!isIE) event.target.value = ""
  }

  const importFilesFromArchive = (behandling: Behandling) => {
    dispatch(
      showDialog({
        dialogType: "IMPORT_DOCUMENTS_FROM_ARCHIVE",
        dialogProps: {
          behandling: behandling,
          plan,
          showRowDetails: focusRow
        } as ImportDocumentDialogProps
      })
    )
  }

  const rearrangeDocuments = (behandling: Behandling) => {
    dispatch(
      showDialog({
        dialogType: "REARRANGE_DOCUMENTS",
        dialogProps: {
          plan,
          behandling: behandling,
          documents: orderBy(
            behandling.dokumenter,
            ["rekkefolgeBehandling", "dokumentType"],
            ["asc", "asc"]
          )
        }
      })
    )
  }

  return {
    create,
    edit,
    importFilesFromArchive,
    importFilesFromDisk,
    rearrangeDocuments
  }
}
