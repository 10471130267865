import _ from "lodash-es"
import * as React from "react"
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Radio,
  Typography,
  TableHead
} from "@mui/material"
import { hideDialog, showDialog } from "../../../store/dialog/actions"
import { ConnectedReduxProps } from "../../../store"
import { Arealplan } from "../../../hooks/arealplaner/types"
import { MoveDocumentDialogProps } from "./MoveDocumentFormDialog"
import { SelectedDocument } from "../../../store/selectedDocumentsTable/reducer"
import { Behandling } from "../../../hooks/behandlinger/types"
import { formatDate } from "../../../utils/formatDate"
import GenericTable from "../../../components/GenericTable"
import { sortBehandlingList } from "../../behandling/BehandlingList/helpers"
import {
  documentIsArvet,
  documentHasBehandling
} from "../../../utils/documentIsArvet"
import useBehandlinger from "../../../hooks/behandlinger/useBehandlinger"
import useArealplanDokumenter from "../../../hooks/dokumenter/useArealplanDokumenter"

export interface SelectBehandlingDialogProps extends ConnectedReduxProps {
  open: boolean
  plan: Arealplan
  selectedDocuments: SelectedDocument[]
}

function SelectBehandlingDialog({
  dispatch,
  open,
  selectedDocuments,
  plan
}: SelectBehandlingDialogProps) {
  const { data: dokumenter } = useArealplanDokumenter()
  const { data: behandlinger } = useBehandlinger()
  const [
    selectedBehandling,
    setSelectedBehandling
  ] = React.useState<Behandling>()
  const refSelectedBehandling = React.useRef(selectedBehandling)
  refSelectedBehandling.current = selectedBehandling
  const [
    hasAcceptedDocumentsThatCannotBeMoved,
    setHasAcceptedDocumentsThatCannotBeMoved
  ] = React.useState(false)

  const isChecked = (beh): boolean => {
    return refSelectedBehandling.current
      ? refSelectedBehandling.current.id === beh.id
      : false
  }
  const columns = [
    {
      title: null,
      tooltip: "Velg behandling",
      render: beh => (
        <Radio
          value={beh}
          onChange={() => setSelectedBehandling(beh)}
          checked={isChecked(beh)}
        />
      )
    },
    {
      title: "Dato",
      field: "fraDato",
      cellStyle: {
        whiteSpace: "nowrap"
      } as React.CSSProperties,
      render: behandling =>
        formatDate(behandling.fraDato) +
        (behandling.tilDato ? " - " + formatDate(behandling.tilDato) : "")
    },
    {
      title: "Behandlingstype",
      render: behandling => (
        <>
          <Typography>{behandling.behandlingstype}</Typography>
        </>
      )
    },
    {
      title: "Dokumenter",
      render: behandling => (
        <span
          style={{
            whiteSpace: "nowrap"
          }}>
          {behandling.dokumenter.length === 0
            ? ""
            : behandling.dokumenter.length === 1
            ? "1 dokument"
            : `${behandling.dokumenter.length} dokumenter`}
        </span>
      )
    },
    {
      title: "Beskrivelse",
      field: "beskrivelse",
      cellStyle: {
        wordBreak: "keep-all",
        width: "100%"
      } as React.CSSProperties,
      headerStyle: {
        width: "100%"
      } as React.CSSProperties
    }
  ]
  // Check and warn if any files are larger than file size limit
  function handleClose() {
    setHasAcceptedDocumentsThatCannotBeMoved(false)
    hideDialog(dispatch)
  }
  // Uploads all files and expand details of behandling after completion
  function handleSubmit() {
    // Filter which files should be sent
    dispatch(
      showDialog({
        dialogType: "MOVE_DOCUMENTS",
        dialogProps: {
          selectedDocuments: documentsThatCanBeMoved,
          behandling: selectedBehandling,
          plan
        } as MoveDocumentDialogProps
      })
    )
  }

  const documentsThatCanBeMoved = selectedDocuments.filter(
    doc =>
      !documentIsArvet(doc.dokumentId, plan, dokumenter) &&
      !documentHasBehandling(doc.dokumentId, plan, dokumenter)
  )
  const arvetDocuments = selectedDocuments.filter(doc =>
    documentIsArvet(doc.dokumentId, plan, dokumenter)
  )

  const documentsWithBehandling = selectedDocuments.filter(doc =>
    documentHasBehandling(doc.dokumentId, plan, dokumenter)
  )

  const documentsThatCannotBeMoved = arvetDocuments.concat(
    documentsWithBehandling
  )

  const getPreInfoText = () => {
    //Check if any or all documents cannot be moved
    if (documentsThatCannotBeMoved.length) {
      if (documentsThatCanBeMoved.length) {
        return (
          <div>
            <p>{`${documentsThatCannotBeMoved.length} ${
              documentsThatCannotBeMoved.length === 1
                ? "dokument kan ikke flyttes fordi det enten er arvet fra en annen plan, eller allerede er knyttet til en behandling:"
                : "dokumenter kan ikke flyttes fordi de enten er arvet fra en annen plan, eller allerede er knyttet til en behandling:"
            }`}</p>
            <ul>
              {documentsThatCannotBeMoved.map(doc => (
                <li key={doc.dokumentId}>{doc.filename}</li>
              ))}
            </ul>
            <p>{`${documentsThatCanBeMoved.length} kan flyttes:`}</p>
            <ul>
              {documentsThatCanBeMoved.map(doc => (
                <li key={doc.dokumentId}>{doc.filename}</li>
              ))}
            </ul>
          </div>
        )
      } else {
        return `${
          documentsThatCannotBeMoved.length === 1 ? "Dokumentet" : "Dokumentene"
        } kan ikke flyttes fordi ${
          documentsThatCannotBeMoved.length === 1 ? "det" : "de"
        } er arvet fra en annen plan, eller allerede er knyttet til en behandling`
      }
    } else {
      return false
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="import-document-title"
      maxWidth="md"
      scroll="paper"
      PaperProps={{ style: { overflowY: "visible" } }}>
      {documentsThatCannotBeMoved.length &&
      !hasAcceptedDocumentsThatCannotBeMoved ? (
        <>
          <DialogContent>{getPreInfoText()}</DialogContent>
          <DialogActions>
            {documentsThatCanBeMoved.length ? (
              <>
                <Button variant="contained" color="grey" onClick={handleClose}>
                  Avbryt
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    setHasAcceptedDocumentsThatCannotBeMoved(true)
                  }>
                  Fortsett
                </Button>
              </>
            ) : (
              <Button variant="contained" color="primary" onClick={handleClose}>
                OK
              </Button>
            )}
          </DialogActions>
        </>
      ) : (
        ""
      )}
      {(hasAcceptedDocumentsThatCannotBeMoved ||
        !documentsThatCannotBeMoved.length) && (
        <>
          <DialogTitle id="import-document-title">
            {documentsThatCanBeMoved.length === 1
              ? "Flytt 1 dokument"
              : `Flytt ${documentsThatCanBeMoved.length} dokumenter`}
          </DialogTitle>
          <DialogContent>
            <GenericTable
              title="Planbehandlinger"
              data={sortBehandlingList(behandlinger)}
              columns={columns}
              idColumn="id"
              onRowClick={(event, rowData) => setSelectedBehandling(rowData)}
              components={{
                Header: props => (
                  <>
                    <colgroup>
                      <col style={{ width: "170px" }} />
                      <col style={{ width: "270px" }} />
                      <col style={{ width: "150px" }} />
                      <col style={{ width: "200px" }} />
                      <col style={{ width: "auto" }} />
                    </colgroup>
                    <TableHead {...props} />
                  </>
                )
              }}
            />
          </DialogContent>
          <DialogActions>
            <>
              <Button onClick={handleClose} variant="contained" color="grey">
                Avbryt
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
                disabled={!selectedBehandling}>
                Flytt til valgt behandling
              </Button>
            </>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
export default SelectBehandlingDialog
