import axios from "axios"
import config from "../config"

const kommuneinfo = axios.create({
  baseURL: config.kommuneinfo.url,
  headers: { "X-WAAPI-TOKEN": config.planregister.token }
})

export interface KommuneInfoResp {
  KommuneObject: {
    FylkeArea: number
    FylkeName: {
      nor: String
    }
    FylkeNumber: string
    KommuneBox: string
    KommuneCrest: string
    KommuneGeometry: string
    KommuneName: { nor: string }
    KommuneNumber: string
  }
}

export const getMunicipalityGeometry = (
  knr: string
): Promise<KommuneInfoResp | false> => {
  return kommuneinfo
    .get<KommuneInfoResp>(getRegionFromMunicipalityIdReqExtension(knr))
    .then(
      resp => resp.data,
      error => false
    )
}

function getRegionFromMunicipalityIdReqExtension(
  municipality_id: string
): string {
  const apiTargets =
    "?KommuneAdditionalGeometry=true&GeometryTextFormat=GeoJson"
  const url = `/kommune/number/${municipality_id}${apiTargets}`
  return url
}

export function getMunicipalities() {
  return kommuneinfo.get("/kommune")
}
