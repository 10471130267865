import { action } from "typesafe-actions"
import { AuthState, AuthActionTypes as actionTypes } from "./types"
import { Authorization } from "@norkart/nk-msal"

export const setAuthorization = (data: Authorization) =>
  action(actionTypes.SET_AUTH_STATE, data)

export const resetAuthState = () => action(actionTypes.RESET_AUTH_STATE)

export const setAuthIsLoading = (isLoading: boolean) =>
  action(actionTypes.SET_AUTH_IS_LOADING, isLoading)
