import React, { useEffect } from "react"
import { ToiBox, ToiLink, ToiTypography } from "@norkart/toi-components"
import { AppExternalIcon } from "@norkart/toi-icons"
import { ToiThemeProvider } from "@norkart/toi-theme"
import { useMittInnspillProjects } from "../../../hooks/mittinnspill/useMittInnspillProjects"
import config from "../../../config"

const BASE_URL = config.mittinnspill.url

function MittInnspillLink() {
  const mittInnspillProjects = useMittInnspillProjects()
  if (mittInnspillProjects.isLoading) return null
  if (mittInnspillProjects?.data.totalCount === 0) return null

  if (!mittInnspillProjects.data.elements.length) return null
  const project = mittInnspillProjects?.data?.elements[0]

  return (
    <ToiThemeProvider>
      <ToiBox>
        <ToiTypography mb={2}>
          Kommunen har lagt ut planen på MittInnspill.
          <br /> Gå inn på MittInnspill for å gi innspill og se planen i en mer
          visuell form.
        </ToiTypography>
        <ToiLink
          href={`${BASE_URL}/prosjekter/${project.id}`}
          target="_blank"
          mb={2}
          EndIcon={AppExternalIcon}>
          Gå til MittInnspill
        </ToiLink>
      </ToiBox>
    </ToiThemeProvider>
  )
}

export default MittInnspillLink
