import * as React from "react"
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material"
import { hideDialog, showDialog } from "../../../store/dialog/actions"
import { Behandling } from "../../../hooks/behandlinger/types"
import { moveDocumentFromPlan } from "../../../services/api"
import axios, { AxiosPromise } from "axios"
import { Arealplan } from "../../../hooks/arealplaner/types"
import { openSnackbarMessage } from "../../../components/SnackbarMessage"
import LoadingSpinner from "../../../components/LoadingSpinner"
import {
  SelectedDocument,
  removeDocument
} from "../../../store/selectedDocumentsTable/reducer"
import { formatDate } from "../../../utils/formatDate"
import MoveDocumentFormItem, { MovableDocument } from "./MoveDocumentFormItem"
import { useDispatch } from "react-redux"
import { DokumentUtvidet } from "../../plan/PlanDocumentList/PlanDocumentListDocuments"
import useArealplanDokumenter from "../../../hooks/dokumenter/useArealplanDokumenter"
import { useQueryClient } from "react-query"
import useMeta from "../../../hooks/meta/useMeta"

export interface MoveDocumentDialogProps {
  open: boolean
  behandling: Behandling
  plan: Arealplan
  selectedDocuments: SelectedDocument[]
}

export interface SelectedDocumentExtended extends SelectedDocument {
  DokumenttypeId: number
  VisIPlandokument: boolean
  VisIGjeldendeBestemmelser: boolean
  TilgangId: number
}

function MoveDocumentDialog({
  open,
  selectedDocuments,
  behandling,
  plan
}: MoveDocumentDialogProps) {
  const dispatch = useDispatch()
  const meta = useMeta()
  const queryClient = useQueryClient()
  const {
    data: dokumenter,
    refetch: refetchArealplanDokumenter
  } = useArealplanDokumenter()
  const [isMoving, setIsMoving] = React.useState(false)

  React.useEffect(() => {
    if (selectedDocuments.length === 0) handleClose()
  }, [selectedDocuments])

  const initMovableDocuments = (): MovableDocument[] => {
    const res: MovableDocument[] = []
    selectedDocuments.forEach(selDoc => {
      const dok: DokumentUtvidet | undefined = dokumenter.find(
        doc => selDoc.dokumentId === doc.id
      )
      if (dok) {
        const movableDok: MovableDocument = {
          id: selDoc.dokumentId,
          dokumentnavn: selDoc.filename,
          dokumenttypeId: dok.dokumenttypeId,
          dokumentdato: dok.dokumentdato,
          visIGjeldendeBestemmelser: false,
          visIPlandokumenter: false,
          tilgangId: 1
        }
        res.push(movableDok)
      }
    })
    return res
  }

  function backToBehandlingDialog() {
    dispatch(
      showDialog({
        dialogType: "SELECT_BEHANDLING",
        dialogProps: {
          selectedDocuments,
          plan
        }
      })
    )
  }

  const [movableDocuments, setMovableDocuments] = React.useState<
    MovableDocument[]
  >(initMovableDocuments())

  function handleClose() {
    hideDialog(dispatch).then(() => {
      setIsMoving(false)
    })
  }

  function moveFileToBehandling(
    dokument: MovableDocument,
    behandling: Behandling
  ) {
    return moveDocumentFromPlan(dokument, behandling.id)
  }

  // Uploads all files and expand details of behandling after completion
  function handleSubmit() {
    let reqs = [] as AxiosPromise[]

    movableDocuments &&
      movableDocuments.forEach(file => {
        //Move doc
        reqs.push(moveFileToBehandling(file, behandling))
      })
    setIsMoving(true)
    axios
      .all(reqs)
      .then(res => {
        res.forEach(response => {
          dispatch(removeDocument(response.data.id))
        })
      })
      .catch(err =>
        openSnackbarMessage({
          message:
            "Noe gikk galt under flytting av filer. Vennligst prøv igjen.",
          variant: "error"
        })
      )
      .finally(() => {
        queryClient.invalidateQueries([
          "behandlinger",
          { arealplanId: plan.id }
        ])
        queryClient.invalidateQueries(["arealplan", plan.id])
        refetchArealplanDokumenter()
        handleClose()
      })
  }

  const onChange = (updatedDocument: MovableDocument) => {
    const updatedDocList: MovableDocument[] = movableDocuments.map(doc => {
      if (doc.id === updatedDocument.id) {
        return updatedDocument
      } else {
        return doc
      }
    })
    setMovableDocuments(updatedDocList)
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="import-document-title"
      maxWidth="xl"
      scroll="paper"
      PaperProps={{ style: { overflowY: "visible" } }}>
      <DialogTitle id="import-document-title">
        Flytt til behandling: {behandling.behandlingstype}{" "}
        {formatDate(behandling.fraDato) +
          (behandling.tilDato ? " - " + formatDate(behandling.tilDato) : "")}
      </DialogTitle>
      <DialogContent>
        {isMoving ? (
          <>
            <Typography>Flytter valgte dokumenter til behandling</Typography>
            <LoadingSpinner />
          </>
        ) : (
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant="head">Dokumenttype</TableCell>
                  <TableCell variant="head">Dokumentnavn</TableCell>
                  <TableCell variant="head">Dato</TableCell>
                  <TableCell variant="head" style={{ whiteSpace: "normal" }}>
                    Vis i gjeldende bestemmelser
                  </TableCell>
                  <TableCell variant="head" style={{ whiteSpace: "normal" }}>
                    Vis i andre dokumenter
                  </TableCell>
                  <TableCell variant="head" style={{ whiteSpace: "normal" }}>
                    Dokumenttilgang
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedDocuments.map((document, index) => (
                  <MoveDocumentFormItem
                    key={`file-${index}`}
                    data={movableDocuments[index]}
                    dokumenttyper={meta.data.dokumenttyper}
                    tilganger={meta.data.tilgangstyper}
                    onChange={onChange}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!isMoving ? (
          <>
            <Button
              onClick={backToBehandlingDialog}
              variant="contained"
              color="grey">
              Tilbake
            </Button>
            <Button onClick={handleClose} variant="contained" color="grey">
              Avbryt
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
              disabled={!selectedDocuments}>
              Lagre
            </Button>
          </>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

export default MoveDocumentDialog
