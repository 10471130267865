import { useMittInnspillProjects } from "./useMittInnspillProjects"

export function useHasMittInnspillProject() {
  const projects = useMittInnspillProjects()

  return (
    projects.isFetched &&
    projects.data &&
    projects.data.totalCount !== 0 &&
    Boolean(projects?.data?.elements?.length) &&
    Boolean(projects?.data?.elements[0])
  )
}
