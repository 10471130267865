import * as React from "react"
import ExternalLink from "../../../components/ExternalLink"
import useMeta from "../../../hooks/meta/useMeta"
import PlanFeedbackForm from "./PlanFeedbackForm"
import MittInnspillLink from "./MittInnspillLink"

function PlanFeedbackContent() {
  const meta = useMeta()

  if (meta.hasTilbakemeldingEgetSkjema()) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ExternalLink
          url={meta.tilbakemeldingEgetSkjemaUrl}
          linkText="Tilbakemeldings skjema"
        />
      </div>
    )
  }

  return (
    <>
      {meta.hasMittInnspill() && <MittInnspillLink />}
      {meta.hasTilbakemelding() && <PlanFeedbackForm />}
    </>
  )
}

export default PlanFeedbackContent
