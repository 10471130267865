import React from "react"
import { Arealplan } from "../../hooks/arealplaner/types"
import { formatDate } from "../../utils/formatDate"
import { Typography } from "@mui/material"

interface IkraftDatoDisplayProps {
  plan: Arealplan
}

const IkraftDatoDisplay = (props: IkraftDatoDisplayProps) => {
  return (
    <Typography
      sx={{
        ...(_shouldBeDisabled(props.plan.planStatusId) && {
          color: "grey",
          fontStyle: "italic"
        })
      }}>
      {formatDate(props.plan.iKraft)}
    </Typography>
  )
}

const _shouldBeDisabled = (planstatusId: number) => {
  // Tydelig visning av at ikraftdato er utgått på planer med status 4 og 5
  return [4, 5].includes(planstatusId)
}

export default IkraftDatoDisplay
