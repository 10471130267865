import axios, { CancelTokenSource, CancelTokenStatic } from "axios"
import config from "../config"

let CancelToken: CancelTokenStatic
let source: CancelTokenSource

const BASE_URL = config.mittinnspill.apiUrl + "/api"

const mittinnspillApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "X-WAAPI-PROFILE": config.planregister.profile,
    "X-WAAPi-Token": config.planregister.token
  }
})

export const getProjectsBySearch = ({
  planid,
  komnr,
  tenantId
}: {
  planid: string
  komnr?: string | null
  tenantId?: string | null
}) => {
  if (CancelToken) {
    source.cancel("cancel search")
  }
  CancelToken = axios.CancelToken
  source = CancelToken.source()
  let url = `${BASE_URL}/projects/search?planid=${planid}`
  if (komnr) url += `&municipalitynr=${komnr}`
  if (tenantId) url += `&tenantid=${tenantId}`

  return mittinnspillApi.get(url, {
    cancelToken: source.token
  })
}
