import _ from "lodash-es"
import * as React from "react"
import { Form, Field } from "react-final-form"
import {
  Typography,
  Button,
  Grid,
  FormGroup,
  FormControl,
  FormControlLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Collapse,
  Tooltip,
  Box
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import DatePicker from "../../../components/DatePicker"
import { Arealplan, Sak, PlanId } from "../../../hooks/arealplaner/types"
import LoadingSpinner from "../../../components/LoadingSpinner"
import FormSelectInput from "../../../components/FormSelectInput/FormSelectInput"
import FormInputField from "../../../components/FormInputField"
import Checkbox from "../../../components/Checkbox"
import Error from "../../../components/FormError/Error"
import Warning from "./components/Warning"
import {
  isLovRefRequired,
  isLovRefDefault,
  isForslagsstillerAllowed,
  isVertNivaRequired,
  isIKraftRequired,
  isVertNivaEnabled,
  isIKraftEnabled,
  isPlanBestemmelserSuggested,
  isUbehandletEnabled,
  shouldDisplayKlagefrist,
  shouldDisplayHoringsfrist
} from "./helpers"
import { OnChange } from "react-final-form-listeners"
import createDecorator from "final-form-focus"
import { FieldArray } from "react-final-form-arrays"
import arrayMutators from "final-form-arrays"
import validate from "./validate"
import { openSnackbarMessage } from "../../../components/SnackbarMessage/SnackbarMessage"
import { parseDate } from "../../../utils/parseDate"
import { formatNasjonalArealplanId } from "../../../utils/formatNasjonalArealplanId"
import { AxiosError } from "axios"
import { getNewPlanId } from "../../../services/api"
import EditSakDialog from "./EditSakDialog"
import SakList from "../../sak/SakList"
import { useEffect, useState } from "react"
import useMeta from "../../../hooks/meta/useMeta"
import { firstLower } from "../../../utils/firstLower"

const focusOnErrors = createDecorator()

const formGroupHeight = 72

export interface PlanFormProps {
  plan: Arealplan
  errors?: any
  onSubmit: (values: any) => any
  onCancel: () => void
  onDelete: () => void
  onClose: () => void
}

export interface PlanFormState {
  initialValues: ArealplanFormValues
  expanded: boolean // Controls if extra properties pane is open
  planidOpen: boolean // Controls if Edit planid is open
  confirmSubmitOpen: boolean // Controls confirm dialog
  confirmSubmitConfirmed: boolean // Set if ok if clicked in confirm dialog
  editSakOpen: boolean
  internalErrors?: AxiosError
  updateQMS: boolean
}

export interface ArealplanFormValues {
  komnr: string
  planId: PlanId
  navn?: string
  planTypeId?: number
  planStatusId?: number
  iKraft?: Date
  planBestemmelseId?: number
  vertikalniva?: number[]
  lovreferanseId?: number
  forslagsstillerId?: number
  formatId?: number
  opprinneligAdministrativEnhet?: number
  opprinneligPlanId?: PlanId
  overstyrtPlanid?: boolean
  plandokumentasjonOppdatert?: boolean
  ubehandletKlage?: boolean
  ubehandletInnsigelse?: boolean
  klagerFerdigbehandlet?: boolean
  klagefrist?: Date
  horingsStart?: Date
  horingsfrist?: Date
  meldingKlagefrist?: boolean
  saker: Partial<Sak>[]
  updateQMS?: boolean
}

const WhenFieldChanges = ({ field, predictor, set, to }: any) => (
  <Field name={set} subscription={{}}>
    {({ input: { onChange } }) => (
      <OnChange name={field}>
        {value => {
          if (predictor(value)) {
            onChange(to)
          }
        }}
      </OnChange>
    )}
  </Field>
)

function PlanForm({ plan, ...props }: PlanFormProps) {
  const [initialValues, setInitialValues] = useState<ArealplanFormValues>(
    {} as ArealplanFormValues
  )
  const [expanded, setExpanded] = useState(false)
  const [planidOpen, setPlanidOpen] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false)
  const [confirmSubmitConfirmed, setConfirmSubmitConfirmed] = useState(false)
  const [editSakOpen, setEditSakOpen] = useState(false)
  const [internalErrors, setInternalErrors] = useState()

  const meta = useMeta()

  useEffect(() => {
    plan.planId
      ? setInitialState(plan)
      : getNewPlanId().then(res =>
          setInitialState({
            planId: res.data as PlanId,
            komnr: meta.komnr || "",
            vertikalniva: [],
            saker: []
          } as ArealplanFormValues)
        )
  }, [])

  const setInitialState = initialState => {
    const { dokumenter, behandlinger, gjenstaendeKlage, ...filteredState } =
      initialState
    let newState = {
      initialValues: {
        ...filteredState,
        klagerFerdigbehandlet: !gjenstaendeKlage,
        vertikalniva: filteredState.vertikalniva.map(v => v.id),
        lovreferanseId:
          !filteredState.lovreferanseId &&
          isLovRefDefault(filteredState.planTypeId)
            ? 6
            : filteredState.lovreferanseId
      } as ArealplanFormValues
    }
    setInitialValues(newState.initialValues)
  }

  const handleErrors = response => {
    const errors = response.errors
    let submitErrors = {}
    Object.keys(errors).map(key => {
      const fieldId = firstLower(key)
      submitErrors[fieldId] = errors[key][0]
    })

    return submitErrors
  }

  // Maps vertikalnivå from array to array of objects
  // Determines if PlanId is modified
  // Converts PlandokumentasjonOppdatert from boolean to smallint
  const mapDataOut = values => {
    let vertikalNivaMap = new Array()
    if (values.vertikalniva && values.vertikalniva.length > 0) {
      vertikalNivaMap = values.vertikalniva.map(vn => ({ id: vn }))
    }

    const { klagerFerdigbehandlet, ...filteredValues } = values

    let data = {
      ...filteredValues,
      iKraft: isIKraftEnabled(values.planStatusId) ? values.iKraft : null,
      overstyrtPlanid:
        initialValues.overstyrtPlanid || values.planId != initialValues.planId,
      vertikalniva: isVertNivaEnabled(values.planTypeId, values.planStatusId)
        ? vertikalNivaMap
        : null,
      forslagsstillerId: isForslagsstillerAllowed(values.planTypeId)
        ? values.forslagsstillerId
        : null,
      gjenstaendeKlage: !values.klagerFerdigbehandlet
    }
    return data
  }

  const hasNasjonalPlanIdChanged = ({ komnr, planId }) => {
    let planIdChanged = planId != initialValues.planId
    let komNrChanged = komnr != initialValues.komnr

    return planIdChanged || komNrChanged
  }

  // Async in order to properly return submit errors
  const onSubmit = async (values: ArealplanFormValues) => {
    // Show confirm dialog if Nasjonal arealplan-id changed from initialValues
    if (hasNasjonalPlanIdChanged(values) && !confirmSubmitConfirmed) {
      handleConfirmOpen()
    } else {
      // Modify form data before submit
      const data = mapDataOut(values)

      // Actual submission of form data
      try {
        const response = await props.onSubmit(data)
        // Handle form validation errors
        if (response?.errors) {
          openSnackbarMessage({
            variant: "error",
            message: "Noe er galt i skjema"
          })
          return handleErrors(response)
        } else {
          props.onClose()
        }
      } catch (err) {
        // TODO: Handle other errors
        // setInternalErrors(err)
      }
    }
  }

  // Handles submit confirm dialog
  const handleConfirmOpen = () => {
    setConfirmSubmitOpen(true)
  }
  const handleSubmitClose = () => {
    setConfirmSubmitOpen(false)
  }

  useEffect(() => {
    if (confirmSubmitConfirmed)
      document
        .getElementById("plan-form")!
        .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))
  }, [confirmSubmitConfirmed])

  const handleSubmitOk = () => {
    setConfirmSubmitOpen(false)
    setConfirmSubmitConfirmed(true)
  }

  const confirmSubmitDialog = () => (
    <Dialog open={confirmSubmitOpen} aria-labelledby="confirm-dialog-title">
      <DialogTitle id="confirm-dialog-title">
        Lagre med endring på nasjonal arealplanid?
      </DialogTitle>
      <DialogContent>
        <Typography>
          Nasjonal arealplanid er endret. Dette kan påvirke koblingen til andre
          systemer. Ønsker du å lagre dette?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmitClose} variant="contained" color="grey">
          Nei
        </Button>
        <Button
          type="submit"
          onClick={handleSubmitOk}
          variant="contained"
          color="secondary">
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  )

  const submitForm = () => {
    document
      .getElementById("plan-form")!
      .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))
  }

  const handleSaveMenuSubmit = values => {
    values.updateQMS = false
    submitForm()
  }

  const handleSaveMenuSubmitQMS = values => {
    values.updateQMS = true
    submitForm()
  }

  const handleEditSak = () => {
    setEditSakOpen(true)
  }

  const handleEditSakClose = () => {
    setEditSakOpen(false)
  }

  const handleEditSakOk = values => {
    setInitialValues({
      ...initialValues,
      saker: values.saker.filter(
        sak =>
          sak.sakAar &&
          sak.sakSeknr &&
          (sak.sakAar != "" ||
            sak.sakAar != null ||
            sak.sakSeknr != "" ||
            sak.sakSeknr != null)
      )
    })
    handleEditSakClose()
  }

  if (meta.isLoading || !initialValues.planId) return <LoadingSpinner />
  if (meta.isError || internalErrors) return null
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        decorators={[focusOnErrors]}
        mutators={{
          ...arrayMutators
        }}
        validate={validate}
        render={({
          handleSubmit,
          pristine,
          submitting,
          submitErrors,
          values,
          form,
          errors
        }) => (
          <Box
            sx={theme => ({
              "& .importantFormLabel": { fontWeight: "bold" },
              "& .formInput": { width: "100%", padding: "5px" },
              "& .formInput:disabled": { backgroundColor: "#f0f0f0" },
              "& .formInputError": { border: "1px solid red" },
              marginBottom: "10px"
            })}>
            <form id="plan-form" onSubmit={handleSubmit} autoComplete="off">
              {/* Change LovreferanseId to 6 for certain PlanTypeId values based on predictor being true */}
              <WhenFieldChanges
                field="planTypeId"
                predictor={isLovRefDefault}
                set="lovreferanseId"
                to={"6"}
              />
              <Grid container={true} spacing={8}>
                <Grid item={true} xs={12} md={6}>
                  <FieldArray
                    name="nasjonalPlanId"
                    render={({ meta: { error, submitError } }) => (
                      <>
                        <Typography className="importantFormLabel">
                          Nasjonal arealplanid
                        </Typography>
                        <Button
                          variant="text"
                          style={{
                            textTransform: "none",
                            whiteSpace: "nowrap",
                            fontWeight: 400
                          }}
                          onClick={() => setPlanidOpen(!planidOpen)}>
                          {planidOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          {values.komnr && values.planId ? (
                            formatNasjonalArealplanId(
                              values as unknown as Arealplan
                            )
                          ) : (
                            <Error error="Nasjonal arealplanid er påkrevd" />
                          )}
                        </Button>

                        <Collapse in={planidOpen} style={{ marginTop: "8px" }}>
                          <Grid
                            container={true}
                            spacing={8}
                            sx={{ ...(!planidOpen && { display: "none" }) }}>
                            <Grid item={true} xs={12} md={6}>
                              <FormSelectInput
                                name="komnr"
                                options={meta.data.kommuner}
                                keyCol="kommuneNummer"
                                valueCol="kommuneNavn"
                              />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                              <FormInputField name="planId" maxLength={16} />
                            </Grid>
                          </Grid>
                        </Collapse>
                        <Grid item={true} xs={12}>
                          {(error || submitError) && (
                            <Error error={error || submitError} />
                          )}
                        </Grid>
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  md={6}
                  style={{ height: formGroupHeight }}>
                  <Typography className="importantFormLabel">
                    Saksnummer (saksår + sekvensnummer)
                  </Typography>
                  {values.saker.length <= 1 ? (
                    <Grid
                      container={true}
                      spacing={8}
                      justifyContent="space-between"
                      wrap="nowrap">
                      <Grid
                        item={true}
                        xs={4}
                        style={{ height: formGroupHeight }}>
                        <FormInputField
                          name="saker[0].sakAar"
                          maxLength={4}
                          fieldProps={{
                            parse: value => {
                              if (value == "") return null
                              return Number(value.replace(/[^\d]/g, ""))
                            }
                          }}
                        />
                        <OnChange name="saker[0].sakAar">
                          {(value, prev) => {
                            let saker = initialValues.saker
                            if (saker[0]) saker[0].sakAar = value
                            else if (value != "")
                              saker.push({
                                sakAar: value,
                                arkivId: meta.aktivtArkiv
                              })
                            setInitialValues({
                              ...initialValues,
                              saker
                            })
                          }}
                        </OnChange>
                      </Grid>
                      <Grid item={true} style={{ height: formGroupHeight }}>
                        <FormInputField
                          name="saker[0].sakSeknr"
                          fieldProps={{
                            parse: value => {
                              if (value == "") return null
                              return Number(value.replace(/[^\d]/g, ""))
                            }
                          }}
                        />
                        <OnChange name="saker[0].sakSeknr">
                          {(value, prev) => {
                            let saker = initialValues.saker
                            if (saker[0]) saker[0].sakSeknr = value
                            else if (value != "")
                              saker.push({
                                sakSeknr: value,
                                arkivId: meta.aktivtArkiv
                              })
                            setInitialValues({
                              ...initialValues,
                              saker
                            })
                          }}
                        </OnChange>
                      </Grid>
                      <Grid item={true} style={{ height: formGroupHeight }}>
                        <Tooltip title="Legg til saksnummer">
                          <IconButton onClick={handleEditSak} size="small">
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container={true}
                      spacing={8}
                      justifyContent="space-between"
                      wrap="nowrap">
                      <Grid item={true}>
                        <Typography
                          component="div"
                          style={{ padding: "6px 8px" }}>
                          <SakList saker={values.saker} />
                        </Typography>
                      </Grid>
                      <Grid item={true}>
                        <Tooltip title="Endre saksnummer">
                          <IconButton onClick={handleEditSak} size="small">
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  md={12}
                  style={{ height: formGroupHeight }}>
                  <FormInputField
                    name="planNavn"
                    label="Plannavn"
                    // eslint-disable-next-line
                    autoFocus={values.saker.length > 1}
                  />
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  md={6}
                  style={{ height: `${72 * 4}px` }}>
                  <div style={{ height: formGroupHeight }}>
                    <FormSelectInput
                      name="planTypeId"
                      title="Plantype"
                      options={meta.data.plantyper}
                      keyCol="id"
                      valueCol="beskrivelse"
                    />
                  </div>
                  <div style={{ height: formGroupHeight }}>
                    <FormSelectInput
                      name="lovreferanseId"
                      title="Lovreferanse"
                      options={meta.data.lovreferanser}
                      keyCol="id"
                      valueCol="beskrivelse"
                      disabled={
                        values.planTypeId
                          ? isLovRefRequired(values.planTypeId)
                            ? isLovRefDefault(values.planTypeId)
                            : true
                          : true
                      }
                    />
                    {!plan.lovreferanseId &&
                      values.lovreferanseId &&
                      isLovRefDefault(values.planTypeId) && (
                        <Warning message="Lovreferanse vil bli lagret" />
                      )}
                    {values.lovreferanseId &&
                      !isLovRefRequired(values.planTypeId) && (
                        <Warning message="Lovreferanse vil bli slettet" />
                      )}
                  </div>
                  <div style={{ height: formGroupHeight }}>
                    <FormSelectInput
                      name="forslagsstillerId"
                      title="Forslagsstillertype"
                      options={meta.data.forslagsstillertyper}
                      optional={true}
                      keyCol="id"
                      valueCol="beskrivelse"
                      disabled={
                        values.planTypeId
                          ? !isForslagsstillerAllowed(values.planTypeId)
                          : true
                      }
                    />
                    {values.forslagsstillerId &&
                      !isForslagsstillerAllowed(values.planTypeId) && (
                        <Warning message="Forslagsstiller vil bli slettet" />
                      )}
                  </div>
                  <div style={{ height: formGroupHeight }}>
                    <FormSelectInput
                      name="planBestemmelseId"
                      title="Planbestemmelser"
                      options={meta.data.planbestemmelser}
                      keyCol="id"
                      valueCol="beskrivelse"
                      optional={
                        !isPlanBestemmelserSuggested(values.planStatusId)
                      }
                    />
                    {values.planBestemmelseId == null &&
                      isPlanBestemmelserSuggested(values.planStatusId) && (
                        <Warning message="Planbestemmelser er påkrevd, men kan lagres uten" />
                      )}
                  </div>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <Field name="vertikalniva">
                    {({
                      input,
                      meta: { error, submitError, touched, ...rest }
                    }) => (
                      <div
                        style={{
                          height: `${
                            meta.data.vertikalniva.length * 48 + 17 + 16
                          }px`
                        }}>
                        <Typography
                          className={`${
                            isVertNivaRequired(
                              values.planTypeId,
                              values.planStatusId
                            ) && "importantFormLabel"
                          }`}>
                          Vertikalnivå
                        </Typography>
                        <div
                          style={{
                            border: "1px solid rgb(169, 169, 169)",
                            padding: "2.5px 12px"
                          }}
                          className={`${
                            (error ||
                              ((!values.vertikalniva ||
                                values.vertikalniva.length == 0) &&
                                isVertNivaRequired(
                                  values.planTypeId,
                                  values.planStatusId
                                ) &&
                                submitError)) &&
                            "formInputError"
                          }`}>
                          <FormControl
                            component={"fieldset" as "div"}
                            style={{ width: "100%" }}
                            disabled={
                              !isVertNivaEnabled(
                                values.planTypeId,
                                values.planStatusId
                              )
                            }>
                            <FormGroup>
                              {meta.data.vertikalniva.map(v => (
                                <FormControlLabel
                                  key={v.id}
                                  control={
                                    <Field
                                      name={input.name}
                                      component={Checkbox}
                                      type="checkbox"
                                      value={v.id}
                                    />
                                  }
                                  label={v.beskrivelse}
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                        </div>

                        {values.vertikalniva &&
                          values.vertikalniva.length > 0 &&
                          !isVertNivaEnabled(
                            values.planTypeId,
                            values.planStatusId
                          ) && (
                            <Warning message="Vertikalnivå vil bli slettet" />
                          )}

                        {(error ||
                          ((!values.vertikalniva ||
                            values.vertikalniva.length == 0) &&
                            isVertNivaRequired(
                              values.planTypeId,
                              values.planStatusId
                            ) &&
                            submitError)) && (
                          <Error error={error || submitError} />
                        )}
                      </div>
                    )}
                  </Field>
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  md={6}
                  style={{ height: formGroupHeight }}>
                  <FormSelectInput
                    name="planStatusId"
                    title="Planstatus"
                    options={meta.data.planstatuser}
                    keyCol="id"
                    valueCol="beskrivelse"
                  />
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  md={6}
                  style={{ height: formGroupHeight }}>
                  <Field
                    name="iKraft"
                    parse={parseDate}
                    render={({ input: { name, value, onChange }, meta }) => (
                      <div>
                        <Typography
                          className={`${
                            isIKraftRequired(values.planStatusId) &&
                            "importantFormLabel"
                          }`}>
                          <label htmlFor={name}>Ikrafttredelsesdato</label>
                        </Typography>
                        <DatePicker
                          input={{ name, value, onChange }}
                          disabled={
                            values.planStatusId
                              ? !isIKraftEnabled(values.planStatusId)
                              : true
                          }
                        />
                        {(meta.error || meta.submitError) && (
                          <Error error={meta.error || meta.submitError} />
                        )}
                        {values.iKraft &&
                          !isIKraftEnabled(values.planStatusId) && (
                            <Warning message="Ikrafttredelsesdato vil bli slettet" />
                          )}
                      </div>
                    )}
                  />
                </Grid>
                {shouldDisplayKlagefrist(values) && (
                  <>
                    <Grid item={true} xs={12} md={6}>
                      <FormControlLabel
                        label="Vis melding 'Klagefrist ikke utløpt' før Kunngjøring"
                        control={
                          <Field
                            name="meldingKlagefrist"
                            type="checkbox"
                            component={Checkbox}
                          />
                        }
                      />
                    </Grid>
                    <Grid
                      item={true}
                      xs={12}
                      md={6}
                      style={{ height: formGroupHeight }}>
                      <Field
                        name="klagefrist"
                        parse={parseDate}
                        render={({
                          input: { name, value, onChange },
                          meta
                        }) => (
                          <div>
                            <Typography className={"importantFormLabel"}>
                              <label htmlFor={name}>Klagefrist</label>
                            </Typography>
                            <DatePicker input={{ name, value, onChange }} />
                          </div>
                        )}
                      />
                    </Grid>
                  </>
                )}
                {values.planStatusId == 6 && (
                  <>
                    <Grid item={true} xs={12} md={6}>
                      <FormControlLabel
                        label="Oppsettende(utsatt) virkning av klage"
                        disabled={!isUbehandletEnabled(values.planStatusId)}
                        control={
                          <Field
                            name="ubehandletKlage"
                            type="checkbox"
                            component={Checkbox}
                          />
                        }
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <FormControlLabel
                        label="Ubehandlet innsigelse"
                        disabled={!isUbehandletEnabled(values.planStatusId)}
                        control={
                          <Field
                            name="ubehandletInnsigelse"
                            type="checkbox"
                            component={Checkbox}
                          />
                        }
                      />
                    </Grid>
                  </>
                )}
                {shouldDisplayKlagefrist(values) && (
                  <>
                    <Grid item={true} xs={12}>
                      <FormControlLabel
                        label="Ingen klager under behandling"
                        control={
                          <Field
                            name="klagerFerdigbehandlet"
                            type="checkbox"
                            component={Checkbox}
                          />
                        }
                      />
                    </Grid>
                  </>
                )}
                {shouldDisplayHoringsfrist(values) && (
                  <>
                    <Grid
                      item={true}
                      xs={12}
                      md={6}
                      style={{ height: formGroupHeight }}>
                      <Field
                        name="horingsStart"
                        parse={parseDate}
                        render={({
                          input: { name, value, onChange },
                          meta
                        }) => (
                          <div>
                            <Typography>
                              <label htmlFor={name}>Høringsstart</label>
                            </Typography>
                            <DatePicker input={{ name, value, onChange }} />
                          </div>
                        )}
                      />
                    </Grid>
                    <Grid
                      item={true}
                      xs={12}
                      md={6}
                      style={{ height: formGroupHeight }}>
                      <Field
                        name="horingsfrist"
                        parse={parseDate}
                        render={({
                          input: { name, value, onChange },
                          meta
                        }) => (
                          <div>
                            <Typography>
                              <label htmlFor={name}>Høringsfrist</label>
                            </Typography>
                            <DatePicker input={{ name, value, onChange }} />
                          </div>
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item={true} xs={12} md={9}>
                  <FormControlLabel
                    label="Plandokumentasjonen er oppdatert i henhold til vedtak"
                    control={
                      <Field
                        name="plandokumentasjonOppdatert"
                        type="checkbox"
                        component={Checkbox}
                      />
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} md={12}>
                  <Button variant="text" onClick={() => setExpanded(!expanded)}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    {expanded ? "Vis færre egenskaper" : "Vis flere egenskaper"}
                  </Button>
                  <Collapse in={expanded} timeout="auto">
                    <Grid
                      container={true}
                      spacing={8}
                      justifyContent={"space-between"}
                      sx={{ ...(!expanded && { display: "none" }) }}>
                      <Grid
                        item={true}
                        xs={12}
                        md={6}
                        style={{ height: formGroupHeight }}>
                        <FormSelectInput
                          name="opprinneligAdministrativEnhet"
                          title="Opprinnelig administrativ enhet"
                          options={meta.data.kommuner}
                          keyCol="kommuneNummer"
                          valueCol="kommuneNavn"
                          optional={true}
                        />
                      </Grid>
                      <Grid
                        item={true}
                        xs={12}
                        md={6}
                        style={{ height: formGroupHeight }}>
                        <FormInputField
                          name="opprinneligPlanId"
                          label="Opprinnelig planid"
                          optional={true}
                        />
                      </Grid>
                      <Grid
                        item={true}
                        xs={12}
                        md={6}
                        style={{ height: formGroupHeight }}>
                        <FormSelectInput
                          name="formatId"
                          title="Format"
                          options={meta.data.formater}
                          keyCol="id"
                          valueCol="beskrivelse"
                          optional={true}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
                <Grid item={true} style={{ flexGrow: 1 }}>
                  <div />
                </Grid>
                <Grid
                  container={true}
                  justifyContent="space-between"
                  alignItems="baseline">
                  <Grid item={true}>
                    {plan && plan.id ? (
                      <>
                        <IconButton onClick={props.onDelete}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : null}
                  </Grid>
                  <Grid item={true}>
                    <div style={{ marginTop: "20px", display: "flex" }}>
                      {meta.hasFeature("vis_oppdater_qms") && (
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          disabled={submitting}
                          onClick={() => handleSaveMenuSubmitQMS(values)}>
                          Lagre og oppdater QMS
                        </Button>
                      )}
                      <Button
                        style={{ marginLeft: 10 }}
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={submitting}
                        onClick={() => handleSaveMenuSubmit(values)}>
                        Lagre
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* <pre>Values: {JSON.stringify(values, null, 2)}</pre>
            <pre>Pristine: {JSON.stringify(pristine, null, 2)}</pre>
            <pre>{JSON.stringify(submitting, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
            <pre>{JSON.stringify(submitErrors, null, 2)}</pre> */}
            </form>
          </Box>
        )}
      />
      {confirmSubmitDialog()}
      <EditSakDialog
        saker={initialValues.saker}
        open={editSakOpen}
        onClose={handleEditSakClose}
        onOk={handleEditSakOk}
        arkiver={meta.data.arkiver}
        defaultValue={meta.aktivtArkiv}
      />
    </>
  )
}

export default PlanForm
