import { Reducer } from "@reduxjs/toolkit"
import { AuthState, ResetAuthStateAction, SetAuthIsLoadingAction, SetAuthStateAction, AuthActionTypes as actionTypes } from "./types"
import { Authorization } from "@norkart/nk-msal"

const defaultAuthorization: Authorization = {
  isAuthorized: false,
  getStringSetting: function (name: string): string | undefined {
    return undefined
  },
  getBooleanSetting: function (name: string): boolean | undefined {
    return undefined
  },
  getNumberSetting: function (name: string): number | undefined {
    return undefined
  }
}

const initialState: AuthState = {
  authorization: defaultAuthorization,
  isLoading: false
}

type KnownActions = SetAuthStateAction | SetAuthIsLoadingAction | ResetAuthStateAction;

const reducer: Reducer<AuthState,KnownActions> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH_STATE:
      return {
        ...state,
        authorization: action.payload
      }
    case actionTypes.SET_AUTH_IS_LOADING:
      return { ...state, isLoading: action.payload }
    case actionTypes.RESET_AUTH_STATE:
      return { ...initialState }

    default:
      return state
  }
}

export { reducer as authReducer }

export const getAuthorization = (state: AuthState) => state.authorization
export const getAuthorizationIsLoading = (state: AuthState) => state.isLoading
