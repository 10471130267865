import { ColumnDef } from "@tanstack/react-table"
import React, { useRef, useState } from "react"
import ReactTable from "../../../components/ReactTable"
import { Dispensasjon } from "../../../hooks/dispensasjoner/types"
import useDispensasjoner from "../../../hooks/dispensasjoner/useDispensasjoner"

import { formatDate } from "../../../utils/formatDate"
import DispensasjonListDetailPanel from "./DispensasjonListDetailPanel"

import SakItem from "../../sak/SakList/SakItem"
import { Button } from "@mui/material"
import DisplayPosInMap from "../../map/DisplayPosInMap"
import useArealplan from "../../../hooks/arealplaner/useArealplan"
import useCan from "../../../utils/auth/useCan"
import { useDispensasjonActions } from "./useDispensasjonActions"
import { DispensasjonTableRowMenuButton } from "./DispensasjonTableRowMenuButton"
import AddIcon from "@mui/icons-material/Add"
import { ActionButtonProps } from "../../../components/ActionButtons/ActionButton"
import ActionButtons from "../../../components/ActionButtons"
import { useDispatch, useSelector } from "react-redux"
import { getExpandDispensasjonId } from "../../../store"
import { setExpandDispensasjonId } from "../../../store/session/actions"
import { orderBy } from "lodash-es"

export const DispensasjonTable = () => {
  const dispensasjoner = useDispensasjoner()
  const can = useCan()
  const { data: plan } = useArealplan()
  const selectedDispensasjon = useRef<Dispensasjon>({} as Dispensasjon)
  const [displayPosFor, setDisplayPosFor] = useState<Dispensasjon | null>()
  const actions = useDispensasjonActions()
  const expandId = useSelector(getExpandDispensasjonId)
  const dispatch = useDispatch()

  const onExpandChange = (id: string) => {
    dispatch(setExpandDispensasjonId(id == expandId ? undefined : id))
  }

  const columns = React.useMemo<ColumnDef<Dispensasjon>[]>(
    () => [
      {
        id: "vedtaksdato",
        header: "Vedtaksdato",
        accessorFn: row => row.vedtaksdato,
        size: 120,
        sortingFn: "datetime",
        cell: ({ getValue }) => formatDate(getValue<Date>())
      },
      {
        id: "vedtak",
        header: "Vedtakstype",
        accessorFn: row => row.vedtak,
        size: 170,
        sortingFn: "text"
      },
      {
        id: "dispensasjonsType",
        header: "Dispensasjonstype",
        accessorFn: row => row.dispensasjonType,
        sortingFn: "text"
      },
      {
        id: "beskrivelse",
        header: "Beskrivelse",
        accessorFn: row => row.beskrivelse,
        sortingFn: "text"
      },
      {
        id: "dokumenter",
        header: "Dokumenter",
        accessorFn: row => row.dokumenter,
        size: 151,
        cell: info => (
          <span
            style={{
              whiteSpace: "nowrap"
            }}>
            {info.row.original.dokumenter.length > 0
              ? `${info.row.original.dokumenter.length} dokumenter`
              : ""}
          </span>
        )
      },
      {
        id: "sak",
        header: "Saksnummer",
        size: 151,
        accessorFn: row => row.sak,
        cell: info => (
          <span
            style={{
              whiteSpace: "nowrap"
            }}>
            {info.row.original.sak && <SakItem sak={info.row.original.sak} />}
          </span>
        )
      },
      {
        id: "posisjon",
        header: "Posisjon",
        size: 100,
        accessorFn: row => row.posisjon,
        enableSorting: false,
        cell: info => (
          <span
            style={{
              whiteSpace: "nowrap"
            }}>
            {info.row.original.posisjon?.x && info.row.original.posisjon?.y ? (
              <Button
                onClick={e => {
                  e.stopPropagation()
                  setDisplayPosFor(info.row.original)
                }}>
                Vis i kart
              </Button>
            ) : (
              ""
            )}
          </span>
        )
      },
      ...(can.editDisp
        ? [
            {
              id: "rowMenu",
              enableSorting: false,
              size: 52,
              cell: info => (
                <DispensasjonTableRowMenuButton
                  dispensasjon={info.row.original}
                  onOpen={dispensasjon =>
                    (selectedDispensasjon.current = dispensasjon)
                  }
                />
              )
            }
          ]
        : [])
    ],

    [can.editDisp]
  )
  const actionButtons = [
    {
      title: (
        <>
          <AddIcon /> Ny dispensasjon
        </>
      ),
      label: "Ny dispensasjon",
      onClick: () => {
        actions.create()
      }
    }
  ] as ActionButtonProps[]

  return (
    <div>
      {can.editDisp && <ActionButtons actionButtons={actionButtons} />}

      {displayPosFor?.posisjon && (
        <DisplayPosInMap
          title="Posisjonen til dispensasjonen"
          plan={plan}
          onCloseMap={() => setDisplayPosFor(null)}
          position={displayPosFor.posisjon}
          vertikalnivaId={displayPosFor.vertikalnivaId}
        />
      )}
      <ReactTable
        columns={columns}
        data={orderBy(dispensasjoner.data, "vedtaksdato", ["desc"])}
        toolbar={{ enableFilter: true }}
        renderSubComponent={({ row }) => (
          <DispensasjonListDetailPanel dispensasjon={row.original} />
        )}
        expandId={expandId}
        onExpandChanged={onExpandChange}
        noDataText="Ingen dispensasjoner funnet"
      />
      <input
        id="file-upload"
        type="file"
        multiple={true}
        style={{ display: "none" }}
        onChange={e =>
          actions.importFilesFromDisk(e, selectedDispensasjon.current)
        }
        onClick={e => {
          e.currentTarget.value = ""
        }}
      />
    </div>
  )
}
