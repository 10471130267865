import * as React from 'react';
import {useState} from 'react';
import {
    ToiBox,
    ToiButton,
    ToiDialog,
    ToiTypography,
} from '@norkart/toi-components';
import {clearCachedChatHistory} from '../../cache/chatHistory';

interface ResetButtonProps {
    resetChatHistory: () => void;
}

const ResetButton = (props: ResetButtonProps) => {
    const [open, setOpen] = useState(false);

    const close = () => {
        setOpen(!open);
    };

    const clearHistory = () => {
        clearCachedChatHistory();
        props.resetChatHistory();
        close();
    };

    return (
        <>
            <ToiBox pb={2}>
                <ToiButton variant='outlined' color='secondary' onClick={() => setOpen(!open)}>Nullstill chatten</ToiButton>
            </ToiBox>
            <ToiDialog open={open}>
                <ToiBox m={2} maxWidth={'200px'}>
                    <ToiTypography>
                        Er du sikker på at du vil starte en ny chat? Alt innhold
                        i den eksisterende vil bli slettet.
                    </ToiTypography>
                    <ToiBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2}}>
                        <ToiButton variant='outlined' color='secondary' onClick={() => clearHistory()}>
                            Ja, nullstill
                        </ToiButton>
                        <ToiButton color='secondary' onClick={() => setOpen(!open)}>Gå tilbake</ToiButton>
                    </ToiBox>
                </ToiBox>
            </ToiDialog>
        </>
    );
};

export default ResetButton;
