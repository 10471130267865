import React from "react"
import useArealplan from "../arealplaner/useArealplan"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import { getProjectsBySearch } from "../../services/apiMittInnspill"
import { ListResponseOfProjectResponse } from "./types"
import useMeta from "../meta/useMeta"
import { useQuery } from "react-query"
import useKunde from "../kunder/useKunde"

export function useMittInnspillProjects() {
  const kundeId = useSelectedKundeId()
  const arealplan = useArealplan()
  const meta = useMeta()
  const kunde = useKunde()

  const query = useQuery<ListResponseOfProjectResponse>(
    ["mittinnspill", { kundeId, arealplanId: arealplan.data.id }],
    async () => {
      const { data } = await getProjectsBySearch({
        planid: arealplan.data.planId,
        komnr: kunde.data?.kommunenummer,
        tenantId: kunde.data?.norkartKundeId
      })
      return data
    },
    {
      enabled: !!arealplan.data.id && !!kundeId
    }
  )
  return {
    ...query,
    data: query?.data || ({} as ListResponseOfProjectResponse)
  }
}
