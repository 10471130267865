import { AvailableWmsLayer, WmsLayer } from "../../features/map/types"
import { FitBounds, MapboxLayer } from "@norkart/nkm-mapbox-map"
import { WmsFeature, gfiAttId } from "../../services/apiTjenestekatalogen"

import { PlanId } from "../../hooks/arealplaner/types"

export interface WmsLayersRequestState {
  loading: boolean
  wmsNotFound: boolean
  planId?: PlanId
  wmsLayers?: WmsLayer[] //layer that is drawn on map
  availableWmsLayers?: AvailableWmsLayer[]
  otherWmsLayers?: AvailableWmsLayer[]
  wmsServerUrl?: string
  wmsServiceName?: string
}

export interface GFIAttrState {
  loading: boolean
  notFound: boolean
  planAttributes?: GFIPlanFeature[]
  visibleInMap?: boolean
}
export type GFIPlanFeature = {
  planId: string
  wmsLayers: GFIAttribute[]
}

export type GFIAttribute = {
  wmsLayerName: string
  attrList: WmsFeature[]
}
export type PlanAreaLayers = {
  combined: MapboxLayer
  vertniv1?: MapboxLayer
  vertniv2?: MapboxLayer
  vertniv3?: MapboxLayer
  vertniv1and2?: MapboxLayer
  vertniv1and3?: MapboxLayer
  vertniv2and3?: MapboxLayer
  vertniv1and2and3?: MapboxLayer
}
export interface MapState {
  gfiState: GFIAttrState
  wmsLayerState: WmsLayersRequestState
  showDispInMap: boolean
  drawnAttInMap?: gfiAttId
  differenceLayer?: PlanAreaLayers
  borderLayers?: PlanAreaLayers
  fitBounds?: FitBounds
  selectedPlan?: number
}

//add remove wms layer

export enum MapActionTypes {
  FETCH_WMS_REQUEST = "@@planregister/wmslayers/FETCH_REQUEST",
  FETCH_WMS_SUCCESS = "@@planregister/wmslayers/FETCH_SUCCESS",
  FETCH_WMS_ERROR = "@@planregister/wmslayers/FETCH_ERROR",
  REFRESH_WMS_LAYERS = "@@planregister/wmslayers/REFRESH_WMS_LAYERS",
  ADD_WMS_LAYER = "@@planregister/wmslayers/ADD_LAYER",
  REMOVE_WMS_LAYER = "@@planregister/wmslayers/REMOVE_LAYER",
  FETCH_gfi_REQUEST = "@@planregister/gfilayers/FETCH_REQUEST",
  FETCH_gfi_SUCCESS = "@@planregister/gfilayers/FETCH_SUCCESS",
  FETCH_gfi_ERROR = "@@planregister/gfilayers/FETCH_ERROR",
  SET_VISIBLE_IN_MAP = "@@planregister/gfilayers/SET_VISIBLE_IN_MAP",
  SET_FULLSCREEN_MODE = "@@planregister/map/SET_FULLSCREEN_MODE",
  SET_MAP_MODE = "@@planregister/map/SET_MAP_MODE",
  SET_DIFFERENCE_LAYER = "@@planregister/map/SET_DIFFERENCE_LAYER",
  SET_BORDER_LAYERS = "@@planregister/map/SET_BORDER_LAYERS",
  SET_VERTIKAL_LAYERS = "@@planregister/map/SET_VERTIKAL_LAYERS",
  ADD_FITTOBOUNDS = "@@planregister/map/ADD_FITTOBOUNDS",
  RESET_STATE = "@@planregister/map/RESET_STATE",
  RESET_GFI = "@@planregister/map/RESET_GFI",
  SELECT_PLAN = "@@planregister/map/SELECT_PLAN"
}
