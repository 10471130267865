import React from "react"
import { Typography, Button, Grid, styled } from "@mui/material"
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined"
import { helpObject } from "../../../../help/helpObject"
import HelpButton from "../../../../components/HelpButton/HelpButton"
import useArealplanDokumenter from "../../../../hooks/dokumenter/useArealplanDokumenter"
import useDokuments from "../../../../hooks/dokumenter/useDokuments"

type Props = {}

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.primary.main,
  width: "100%",
  "&:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline"
  },
  padding: "6px 0px "
}))

const GridRoot = styled(Grid)(({ theme }) => ({
  border: `3px solid rgba(30, 116, 0, 0.5)`,
  "&:hover": {
    backgroundColor: "rgba(30, 116, 0, 0.04)",
    borderColor: theme.palette.secondary.main
  },
  paddingRight: "20px",
  paddingLeft: "20px"
}))

const ButtonWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: "10px",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  }
}))

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "10px"
  }
}))

const GjeldendeBestemmelser = (props: Props) => {
  const dokumenter = useArealplanDokumenter()
  const { openDokument } = useDokuments()

  if (!dokumenter.gjeldendeBestemmelser.length) return null
  return (
    <Container>
      {dokumenter.gjeldendeBestemmelser.length > 1 ? (
        <ButtonWrapper>
          <GridRoot container={true} direction="column" wrap="nowrap">
            <Grid container={true} style={{ paddingTop: 10 }} direction="row">
              <Grid justifyContent="flex-start" item={true} xs={6}>
                <Typography
                  variant="subtitle2"
                  style={{ textTransform: "none" }}>
                  Se gjeldende bestemmelser:
                </Typography>
              </Grid>
              <Grid
                xs={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
                item={true}>
                <HelpButton
                  helpText={helpObject.GjeldendeBestemmelser}
                  type="GjeldendeBestemmelser"
                />
              </Grid>
            </Grid>
            {dokumenter.gjeldendeBestemmelser.map(d => (
              <Grid
                xs={12}
                key={d.id}
                item={true}
                style={{
                  alignItems: "center"
                }}>
                <StyledButton
                  variant="text"
                  style={{
                    cursor: "pointer",
                    justifyContent: "flex-start"
                  }}
                  onClick={() => openDokument(d.id)}>
                  <DescriptionOutlined />
                  <Typography
                    sx={{
                      textAlign: "left",
                      wordBreak: "break-all"
                    }}>
                    {d.dokumentnavn}
                  </Typography>
                </StyledButton>
              </Grid>
            ))}
          </GridRoot>
        </ButtonWrapper>
      ) : (
        <ButtonWrapper>
          <GridRoot container={true} wrap="nowrap">
            <Grid
              xs={12}
              item={true}
              style={{
                display: "flex",
                alignItems: "center"
              }}>
              <StyledButton
                style={{ overflow: "visible" }}
                color="secondary"
                variant="text"
                onClick={() =>
                  openDokument(dokumenter.gjeldendeBestemmelser[0].id)
                }>
                <Grid container={true}>
                  <Grid item={true}>
                    <Typography variant="subtitle2">
                      Se gjeldende bestemmelser:
                    </Typography>
                  </Grid>
                  <Grid item={true} style={{ display: "inline-flex" }}>
                    <DescriptionOutlined />
                    <Typography
                      sx={{
                        textAlign: "left",
                        wordBreak: "break-all"
                      }}>
                      {dokumenter.gjeldendeBestemmelser[0].dokumentnavn}
                    </Typography>
                  </Grid>
                </Grid>
              </StyledButton>
            </Grid>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
              item={true}>
              <HelpButton
                helpText={helpObject.GjeldendeBestemmelser}
                type="GjeldendeBestemmelser"
              />
            </Grid>
          </GridRoot>
        </ButtonWrapper>
      )}
    </Container>
  )
}
export default GjeldendeBestemmelser
