import { orderBy } from "lodash-es"
import { Behandling } from "../../../hooks/behandlinger/types"


const planforslagAvvistId = 24 //behandlingId That Sould Be Sorted First
export function sortBehandlingList(behandlinger: Behandling[]) {
  let orderedList = orderBy(
    behandlinger,
    ["fraDato", compareFunctionPlanforslag, "id"],
    ["desc", "desc", "desc"]
  )
  return orderedList
}
function compareFunctionPlanforslag(item: Behandling) {
  if (item.behandlingstypeId === planforslagAvvistId) {
    return 1
  }
  return 0
}

const ShouldHaveHiddenMsgBehandlingId = [19]
export function showChipWithHiddenMsg(behandling: Behandling) {
  if (!behandling.behandlingstypeId) return false
  return (
    ShouldHaveHiddenMsgBehandlingId.includes(behandling.behandlingstypeId) &&
    behandling.tilgang !== "Alle"
  )
}
