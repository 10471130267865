import axios from "axios"
import config from "../config"
import { testVertikalLevelDataRes } from "../features/map/helpers/vertikalLevelDummyData"

const BASE_URL = config.planregister.url + "/api"
const TOKEN = config.planregister.token
const TOKENSTRING = `?apitoken=${TOKEN}`

export type VertikalLevelResponse = {
  omraader: VertikalLevel[]
}

export type VertikalLevel = {
  vertikalnivaa: number
  exterior: geom
  interiors?: geom[]
}
export type geom = { positions: { x: number; y: number }[] }

export const getArealPlanVertnivAreas = (
  knr: number,
  planid: string,
  customerId
): Promise<VertikalLevel[] | undefined> => {
  const path = `${BASE_URL}/gi/kunder/${customerId}/planomraader/${knr}/${planid}${TOKENSTRING}`
  return axios.get<VertikalLevelResponse>(path).then(
    res => res.data.omraader,
    error => {
      console.error("could not fetch vertniv planområder, " + error)
      return undefined
    }
  )
}
