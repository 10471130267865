import axios from "axios"
import { Coords, WmsLayerId } from "../features/map/types"
import { WmsLayersRequestState } from "../store/map/types"
import config from "../config"
import { orderBy } from "lodash-es"


const tjenestepanelet = axios.create({
  baseURL: "https://tjenestekatalogen.api.norkart.no"
})

let CancelToken
let source

export interface WmsLayerGroup {
  CorsEnabled: boolean
  Name: string
  Title: string
  Type: "WMS"
  URL: string
  Layers: LayerType[]
}
export interface LayerType {
  AlphaBlend: number // how transparent the wms layer should be
  DefaultActivated: boolean
  Description: string
  Name: WmsLayerId //Wms-layer name (layer id, KPLAN,KDPLAN,RPLAN,RPLANPAABUNNEN,RPLANU,RPLANO,RPLANFOR,MFT)
  Queryable: boolean
  SRS: string
  ScaleHintMax: number
  ScaleHintMin: number
  CombinedLayers: CombinedLayerType[]
}

export interface CombinedLayerType {
  Name: WmsLayerId //Wms-layer name (layer id, KPLAN,KDPLAN,RPLAN,RPLANPAABUNNEN,RPLANU,RPLANO,RPLANFOR,MFT)
}

export const getMunicipalityWmsLayers = (
  knr: string
): Promise<WmsLayerGroup[] | false> => {
  return tjenestepanelet
    .get<WmsLayerGroup[]>(
      `CatalogueService.svc/json/GetCapabilities?applicationID=${config.tjenestepanel.appid}${knr}`
    )
    .then(
      resp => {
        if (resp && resp.data && resp.data.length) {
          return getWmsLayers(resp.data)
        } else {
          return false
        }
      },
      error => {
        return false
      }
    )
}
function getWmsLayers(resp: WmsLayerGroup[]): WmsLayerGroup[] {
  return resp.filter(cap => cap.Type === "WMS" && cap.CorsEnabled)
}

export type gfiAttId = Brand<string, "gfiAttId">

export type WmsGeometry = { Positions: { X: number; Y: number }[] }

export interface WmsFeature {
  AttributesTree: FeatureAttribute[]
  Description: string
  FeatureType: string
  FeatureTypeReal: string
  Geometry: WmsGeometry
  Interiors: null | WmsGeometry[]
  Visible: boolean
  WMSLayer: string
  WMSName: string
  visibleInMap: boolean
  id: gfiAttId
}
export interface FeatureAttribute {
  Description: string //ID
  Name: string //Display name
  Priority: number
  Value: string
  Visible: boolean
  Undefined?: false
  WmsLayer?: string
  AttributesTree?: FeatureAttribute[]
}

export const getGFI = (
  wmsState: WmsLayersRequestState,
  coords: Coords,
  knr: string
):
  | Promise<{ res?: WmsFeature[]; error?: any; isCancellingToken?: boolean }>
  | undefined => {
  if (wmsState.availableWmsLayers && wmsState.otherWmsLayers) {
    let isCancellingToken = false
    if (CancelToken) {
      isCancellingToken = true
      source.cancel("cancel gfi request")
    }
    CancelToken = axios.CancelToken
    source = CancelToken.source()

    const allLayers = orderBy(
      wmsState.availableWmsLayers.concat(wmsState.otherWmsLayers),
      ["drawOrder"]
    )

    const queryLayers =
      wmsState.wmsServiceName +
      ":" +
      allLayers
        .filter(lay => lay.isVisible)
        .map(lay => lay.Name)
        .join(",") +
      ";"

    return tjenestepanelet
      .get<WmsFeature[]>(
        `FeatureInfoService.svc/json/GetFeatureInfoWithAppId?appId=${config.tjenestepanel.appid}${knr}&tolerance=4
          &srs=EPSG:4326&x=${coords.lng}&y=${coords.lat}&queryLayers=${queryLayers}`,
        { cancelToken: source.token }
      )
      .then(
        resp => {
          return { res: resp.data }
        },
        error => {
          return { error: error, isCancellingToken: isCancellingToken }
        }
      )
  } else {
    console.error("Missing available wms layers")
  }
}
