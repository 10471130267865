import { action } from "typesafe-actions"
import { DokumentUtvidet } from "../../features/plan/PlanDocumentList/PlanDocumentListDocuments"
import { Reducer } from "@reduxjs/toolkit"

export type TableSelection = {
  selectedDocuments: SelectedDocument[]
  allGjeldendeBestDocsSelected: boolean
  allOtherDocsSelected: boolean
}

export interface SelectedDocument {
  url: string
  filename: string
  filepath: string
  dokumentId: number
}

const initialState: TableSelection = {
  selectedDocuments: [],
  allGjeldendeBestDocsSelected: false,
  allOtherDocsSelected: false
}

enum ActionTypes {
  TABLE_REMOVE_DOCUMENTS = "@@planregister/table/dokumenter/REMOVE_LIST",
  TABLE_REMOVE_DOCUMENT = "@@planregister/table/dokumenter/REMOVE",
  ALL_GJELDENDE_BEST_DOCUMENTS_SELECTED = "@@planregister/table/dokumenter/ALL_GJELDENDE_BEST_DOCUMENTS_SELECTED",
  ALL_OTHER_DOCUMENTS_SELECTED = "@@planregister/table/dokumenter/ALL_OTHER_DOCUMENTS_SELECTED",
  ADD_DOCUMENTS = "@@planregister/table/dokumenter/ADD_DOCUMENTS",
  EMPTY_DOCUMENT_SELECTION = "@@planregister/table/dokumenter/EMPTY_DOCUMENT_SELECTION"
}

export const addDocuments = (list: SelectedDocument[]) =>
  action(ActionTypes.ADD_DOCUMENTS, list)

export const removeDocument = (docId: number) =>
  action(ActionTypes.TABLE_REMOVE_DOCUMENT, docId)

export const removeDocuments = (list: DokumentUtvidet[]) =>
  action(ActionTypes.TABLE_REMOVE_DOCUMENTS, list)

export const emptyDocumentSelection = () =>
  action(ActionTypes.EMPTY_DOCUMENT_SELECTION)

export const setAllGjeldendeDocsSelected = (allSelected: boolean) =>
  action(ActionTypes.ALL_GJELDENDE_BEST_DOCUMENTS_SELECTED, allSelected)

export const setAllOtherDocumentsSelected = (allSelected: boolean) =>
  action(ActionTypes.ALL_OTHER_DOCUMENTS_SELECTED, allSelected)

type SelectedDocumentsAction =
  | ReturnType<typeof addDocuments>
  | ReturnType<typeof emptyDocumentSelection>
  | ReturnType<typeof removeDocuments>
  | ReturnType<typeof removeDocument>
  | ReturnType<typeof emptyDocumentSelection>
  | ReturnType<typeof setAllGjeldendeDocsSelected>
  | ReturnType<typeof setAllOtherDocumentsSelected>

const removeListFromList = (
  selectedDocs: SelectedDocument[],
  docs: DokumentUtvidet[]
) =>
  selectedDocs.filter(s => docs.findIndex(doc => doc.id == s.dokumentId) === -1)

const reducer: Reducer<TableSelection, SelectedDocumentsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_DOCUMENTS:
      const newDocuments = action.payload.filter(
        doc =>
          !state.selectedDocuments.find(
            existingDoc => existingDoc.dokumentId === doc.dokumentId
          )
      )
      return {
        ...state,
        selectedDocuments: [...state.selectedDocuments, ...newDocuments]
      }
    case ActionTypes.EMPTY_DOCUMENT_SELECTION:
      return initialState
    case ActionTypes.TABLE_REMOVE_DOCUMENT:
      return {
        ...state,
        selectedDocuments: state.selectedDocuments.filter(
          doc => doc.dokumentId !== action.payload
        )
      }
    case ActionTypes.TABLE_REMOVE_DOCUMENTS:
      return {
        ...state,
        selectedDocuments: removeListFromList(
          state.selectedDocuments,
          action.payload
        )
      }
    case ActionTypes.EMPTY_DOCUMENT_SELECTION:
      return initialState
    case ActionTypes.ALL_GJELDENDE_BEST_DOCUMENTS_SELECTED:
      return {
        ...state,
        allGjeldendeBestDocsSelected: action.payload
      }
    case ActionTypes.ALL_OTHER_DOCUMENTS_SELECTED:
      return {
        ...state,
        allOtherDocsSelected: action.payload
      }
    default:
      return state
  }
}

export const convertListFromDokumentToSelected = (
  list: DokumentUtvidet[]
): SelectedDocument[] => {
  return list.map(doc => {
    return convertFromDokumentToSelected(doc)
  })
}

export const convertFromDokumentToSelected = (
  doc: DokumentUtvidet
): SelectedDocument => {
  return {
    url: doc.url,
    filename: doc.dokumentnavn,
    filepath: doc.visIGjeldendeBestemmelser
      ? "Gjeldende bestemmelser/"
      : doc.dokumentpath,
    dokumentId: doc.id
  }
}

export { reducer as tableSelectionReducer }
