import * as React from "react"

import { Divider, Grid, Typography } from "@mui/material"

import BehandlingList from "../../behandling/BehandlingList"
import DesktopContainer from "../../../containers/DesktopContainer"
import { DispensasjonList } from "../../dispensasjon/DispensasjonList"
import ExpansionPanel from "../../../components/ExpansionPanel"
import Footer from "../../footer"
import JournalList from "../../sak/JournalList"
import LoadingSpinner from "../../../components/LoadingSpinner"
import MobileContainer from "../../../containers/MobileContainer"
import PlanDocumentList from "../PlanDocumentList"
import PlanFeedback from "../PlanFeedback/index"
import PlanInfo from "../PlanInfo/index"
import PlanMap from "../../map/PlanMap"
import PlanProcess from "../PlanProcess"
import PlanRelationships from "../../planforhold/PlanRelationships"
import PlanTabs from "./components/PlanTabs/index"
import useArealplan from "../../../hooks/arealplaner/useArealplan"
import useArealplanDokumenter from "../../../hooks/dokumenter/useArealplanDokumenter"
import useBehandlinger from "../../../hooks/behandlinger/useBehandlinger"
import useCan from "../../../utils/auth/useCan"
import useDispensasjoner from "../../../hooks/dispensasjoner/useDispensasjoner"
import usePlanViewHelpers from "./usePlanViewHelpers"
import { useDispatch } from "react-redux"
import {
  setExpandBehandlingId,
  setExpandDispensasjonId
} from "../../../store/session/actions"
import PlanDescription from "../PlanDescription"

function PlanViewContent() {
  const can = useCan()
  const { data: plan } = useArealplan()
  const behandlinger = useBehandlinger()
  const dispensasjoner = useDispensasjoner()
  const dokumenter = useArealplanDokumenter()
  const helpers = usePlanViewHelpers()

  const [activePage, setActivePage] = React.useState(
    plan.planStatusId == 3 ? "plandokumenter" : "planbehandlinger"
  )

  const [visDispIKart, setVisDispIKart] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (activePage == "dispensasjoner" && !visDispIKart) {
      setVisDispIKart(true)
    } else if (visDispIKart) {
      setVisDispIKart(false)
    }
  }, [activePage])

  const renderTabs = () => {
    if (
      !behandlinger.isFetched ||
      !dokumenter.isFetched ||
      !dispensasjoner.isFetched
    ) {
      return <LoadingSpinner />
    }

    return (
      <Grid container={true} style={{ height: "100%" }}>
        <PlanTabs
          plan={plan}
          onChange={setActivePage}
          activePage={activePage}
        />
        <Grid
          item={true}
          sx={theme => ({
            marginLeft: "30px",
            marginRight: "30px",
            marginTop: "30px",
            padding: theme.spacing(3),
            flexGrow: 1
          })}>
          {activePage == "beskrivelse" && <PlanDescription />}
          {activePage == "plandokumenter" && <PlanDocumentList />}
          {activePage == "planbehandlinger" && <BehandlingList />}
          {activePage == "mindre endringer" && (
            <Typography variant="h2">Endringer</Typography>
          )}
          {activePage == "dispensasjoner" && <DispensasjonList />}
          {activePage == "planforhold" && <PlanRelationships />}
          {activePage == "saksdokumenter" &&
            plan.saker &&
            helpers.shouldShowSaksdokumenter(
              plan.planStatusId,
              plan.saker[0]
            ) && <JournalList />}
          {activePage == "tidsakse" &&
            plan.saker &&
            helpers.shouldShowTidsakse(plan.planStatusId, plan.saker[0]) && (
              <PlanProcess />
            )}
          {activePage == "gi tilbakemelding" &&
            helpers.shouldShowTilbakemelding(plan.planStatusId) && (
              <PlanFeedback />
            )}
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <MobileContainer>
        <PlanInfo visDispIKart={false} />
        {(can.edit ||
          (plan.planBeskrivelse && plan.planBeskrivelse.length > 0)) && (
          <PlanDescription />
        )}
        {(can.edit || dokumenter.data.length > 0) && <PlanDocumentList />}
        {(can.edit || behandlinger.data.length > 0) && <BehandlingList />}
        {(can.editDisp || dispensasjoner.data.length > 0) && (
          <DispensasjonList />
        )}
        {(can.edit ||
          plan.planforholdForlopere.length > 0 ||
          plan.planforholdEtterkommere.length > 0) && <PlanRelationships />}
        {helpers.shouldShowSaksdokumenter(plan.planStatusId, plan.saker[0]) && (
          <ExpansionPanel title="Saksdokumenter">
            <JournalList />
          </ExpansionPanel>
        )}
        {helpers.shouldShowTidsakse(plan.planStatusId, plan.saker[0]) && (
          <PlanProcess />
        )}
        {helpers.shouldShowTilbakemelding(plan.planStatusId) && (
          <PlanFeedback />
        )}
        <Footer />
      </MobileContainer>
      <DesktopContainer>
        <Grid
          container={true}
          direction="column"
          wrap="nowrap"
          sx={{ marginBottom: "50px" }}>
          <Grid item={true} sx={{ width: "100%" }}>
            <Grid
              container={true}
              justifyContent="space-between"
              wrap="nowrap"
              style={{ minHeight: "400px" }}>
              <Grid
                item={true}
                xl={6}
                lg={7}
                md={7}
                xs={7}
                sx={{
                  width: "100%",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  paddingTop: "20px",
                  marginBottom: "30px"
                }}>
                <PlanInfo visDispIKart={visDispIKart} />
              </Grid>
              <Grid item={true} xl={6} lg={5} md={5} xs={5}>
                <PlanMap showDispInMap={visDispIKart} />
              </Grid>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" />
          <Grid item={true}>{renderTabs()}</Grid>
        </Grid>
        <Footer />
      </DesktopContainer>
    </>
  )
}

function PlanView() {
  const { isLoading, isError, isIdle } = useArealplan()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(setExpandDispensasjonId(undefined))
    dispatch(setExpandBehandlingId(undefined))
  }, [])

  if (isLoading || isIdle) return <LoadingSpinner />

  if (isError) {
    return null
  }

  return <PlanViewContent />
}
export default PlanView
