import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import React, { useState } from "react"
import MenuIcon from "@mui/icons-material/MoreHoriz"
import { Dispensasjon } from "../../../hooks/dispensasjoner/types"
import { useDispensasjonActions } from "./useDispensasjonActions"
import useMeta from "../../../hooks/meta/useMeta"

type Props = {
  dispensasjon: Dispensasjon
  onOpen: (dispensasjon: Dispensasjon) => void
}

export const DispensasjonTableRowMenuButton = ({
  dispensasjon,
  onOpen
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const actions = useDispensasjonActions()
  const meta = useMeta()

  const handleMenu = e => {
    setAnchorEl(e.currentTarget)
    onOpen(dispensasjon)
    e.stopPropagation()
  }

  const handleClose = e => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const handleEdit = e => {
    handleClose(e)
    actions.edit(dispensasjon)
  }

  const handleDocumentFromCatalog = e => {
    handleClose(e)
    document.getElementById(`file-upload`)!.click()
  }

  const handleDocumentFromArchive = e => {
    handleClose(e)
    actions.importFilesFromArchive(dispensasjon)
  }

  const handleRearrangeDocuments = e => {
    handleClose(e)
    actions.rearrangeDocuments(dispensasjon)
  }

  const shouldDisplayDocumentFromArchive =
    Boolean(dispensasjon.sak) && meta.hasArkivIntegrasjon()

  const shouldDisplayRearrangeDocuments = dispensasjon.dokumenter?.length > 1

  return (
    <Box display="flex" justifyContent="flex-end">
      <Tooltip title={"Handlinger"}>
        <IconButton
          onClick={e => {
            handleMenu(e)
          }}>
          <MenuIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id={`${dispensasjon.id}-dispensasjon-menu`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock={true}>
        <MenuItem onClick={handleEdit} aria-label="Endre dispensasjon">
          Endre dispensasjon
        </MenuItem>
        <MenuItem
          onClick={handleDocumentFromCatalog}
          aria-label="Last opp dokument">
          Last opp dokument
        </MenuItem>
        {shouldDisplayDocumentFromArchive && (
          <MenuItem
            onClick={handleDocumentFromArchive}
            aria-label="Last opp dokument fra arkiv">
            Last opp dokument fra arkiv
          </MenuItem>
        )}
        {shouldDisplayRearrangeDocuments && (
          <MenuItem
            onClick={handleRearrangeDocuments}
            aria-label="Endre dokumentrekkefølge">
            Endre dokumentrekkefølge
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}
