import React from "react"
import { TableRow, TableCell, Table, TableBody, styled } from "@mui/material"
import { Arealplan } from "../../../../hooks/arealplaner/types"
import { formatNasjonalArealplanId } from "../../../../utils/formatNasjonalArealplanId"
import SakList from "../../../sak/SakList"
import IkraftDatoDisplay from "../../IkraftDatoDisplay"
import HelpButton from "../../../../components/HelpButton/HelpButton"
import { helpObject } from "../../../../help/helpObject"

const wrapSize: number = 1400

export interface Props {
  plan: Arealplan
}

const StyledTable = styled(Table)(({ theme }) => ({
  width: "100%",
  "& td": {
    wordBreak: "keep-all",
    border: 0,
    paddingRight: theme.spacing(8)
  },
  "& tr td:last-child": {
    textAlign: "left",
    width: "100%"
  },
  [theme.breakpoints.down(wrapSize)]: {
    margin: "auto",
    display: "flex",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "20px",
    paddingBottom: "10px"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  /* Desktop view */
  [theme.breakpoints.up(wrapSize)]: {
    height: "30px",
    verticalAlign: "top",
    letterSpacing: "0.05em"
  },
  //Mobile view
  [theme.breakpoints.down(wrapSize)]: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "10px"
  }
}))

const LabelCell = styled(TableCell)({
  textAlign: "end",
  fontWeight: 350,
  textTransform: "uppercase",
  fontSize: "0.95rem"
})

const ValueCell = styled(TableCell)({
  wordBreak: "break-word",
  whiteSpace: "normal"
})

const PlanInfoDetaljer = ({ plan }: Props) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "-20px"
        }}>
        <HelpButton helpText={helpObject.KortPlaninfo} type="Planinfo" />
      </div>
      <StyledTable padding="none">
        <TableBody style={{ width: "100%" }}>
          <StyledTableRow>
            <LabelCell variant="head">Nasjonal arealplanid:</LabelCell>
            <TableCell>{formatNasjonalArealplanId(plan)}</TableCell>
          </StyledTableRow>
          {plan.opprinneligAdministrativEnhet && plan.opprinneligPlanId && (
            <StyledTableRow>
              <LabelCell variant="head">
                Opprinnelig nasjonal arealplanid:
              </LabelCell>
              <TableCell>
                {formatNasjonalArealplanId({
                  komnr: plan.opprinneligAdministrativEnhet,
                  planId: plan.opprinneligPlanId
                })}
              </TableCell>
            </StyledTableRow>
          )}
          <StyledTableRow>
            <LabelCell variant="head">Plantype:</LabelCell>
            <TableCell>{plan.planType}</TableCell>
          </StyledTableRow>
          {plan.planBestemmelse && (
            <StyledTableRow>
              <LabelCell variant="head">Planbestemmelser:</LabelCell>
              <TableCell>{plan.planBestemmelse}</TableCell>
            </StyledTableRow>
          )}
          {plan.lovreferanse && (
            <StyledTableRow>
              <LabelCell variant="head">Lovreferanse:</LabelCell>
              <TableCell>{plan.lovreferanse}</TableCell>
            </StyledTableRow>
          )}
          {plan.vertikalniva.length > 0 && (
            <StyledTableRow>
              <LabelCell variant="head">Vertikalnivå:</LabelCell>
              <ValueCell>
                {plan.vertikalniva &&
                  plan.vertikalniva.map(vn => vn.beskrivelse).join(", ")}
              </ValueCell>
            </StyledTableRow>
          )}
          {plan.forslagsstiller && (
            <StyledTableRow>
              <LabelCell variant="head">Forslagsstillertype:</LabelCell>
              <ValueCell>{plan.forslagsstiller}</ValueCell>
            </StyledTableRow>
          )}
          {plan.saker.length > 0 && (
            <StyledTableRow>
              <LabelCell variant="head">Saksnummer:</LabelCell>
              <ValueCell>
                <SakList saker={plan.saker} />
              </ValueCell>
            </StyledTableRow>
          )}
          {plan.iKraft && (
            <StyledTableRow>
              <LabelCell variant="head">Ikrafttredelsesdato:</LabelCell>
              <TableCell>
                <IkraftDatoDisplay plan={plan} />
              </TableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </StyledTable>
    </>
  )
}
export default PlanInfoDetaljer
