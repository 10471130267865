import { Authorization } from "@norkart/nk-msal"

export interface AuthState {
  authorization: Authorization
  isLoading: boolean
}

export interface SetAuthStateAction {
  type: typeof AuthActionTypes.SET_AUTH_STATE;
  payload: Authorization;
}

export interface SetAuthIsLoadingAction {
  type: typeof AuthActionTypes.SET_AUTH_IS_LOADING;
  payload: boolean;
}

export interface ResetAuthStateAction {
  type: typeof AuthActionTypes.RESET_AUTH_STATE;
}

export enum AuthActionTypes {
  SET_AUTH_IS_LOADING = "@@planregister/AUTH/SET_IS_LOADING",
  SET_AUTH_STATE = "@@planregister/AUTH/SET",
  RESET_AUTH_STATE = "@@planregister/AUTH/RESET"
}
